import { createModule, mutation, action } from 'vuex-class-component';
import CamposTypes, { Campo } from '@/core/types/campos';
import ParametrosTypes from '@/core/types/parametros';
import ParametrosCrmGravacaoTypes from '@/core/types/parametros/parametrosCrmGravacao';
import ParametrosEmpresaCrmGravacaoTypes from '@/core/types/parametros/parametrosEmpresaCrmGravacao';
import ParametrosMapping from '@/core/mappings/parametros';

import { ParametrosCrmDTO } from '@/api/contracts/crm/parametros/AtualizarParametrosCrm';

import { ParametrosCrmGravacao } from '@/core/types/parametros/parametrosCrmGravacao';
import { ParametrosEmpresaCrmGravacao } from '@/core/types/parametros/parametrosEmpresaCrmGravacao';

import CrmClient from '@/api/clients/crm';

const { obterPermissoesUsuario, obterParametrosCrm, obterParametrosPortal, obterParametrosPos} = ParametrosTypes;
const {
  obterParametrosCrmMapping,
  obterPermissoesUsuarioMapping,
  obterParametrosPortalMapping,
  obterParametrosCrmGravacaoMapping,
  obterParametrosEmpresaCrmGravacaoMapping,
  obterSalvarParametrosCrmDTO,
} = ParametrosMapping;
const { obterParametrosCrmGravacao, obterNovoParametrosCrmGravacao } = ParametrosCrmGravacaoTypes;
const { obterParametrosEmpresaCrmGravacao, obterNovoParametrosEmpresaCrmGravacao } = ParametrosEmpresaCrmGravacaoTypes;

const VuexModule = createModule({
  namespaced: 'parametros',
  strict: false,
});

export default class ParametrosModule extends VuexModule {
  permissoesUsuario = obterPermissoesUsuario();
  parametrosCrm = obterParametrosCrm();
  parametrosPortal = obterParametrosPortal();
  parametrosCrmAtualizacao = obterNovoParametrosCrmGravacao();
  parametrosEmpresaCrmAtualizacao = obterNovoParametrosEmpresaCrmGravacao();
  parametrosPos = obterParametrosPos();
  utilizaIntegracaoSalesForce = false;
  analiseDeCreditoAtiva = false;

  @mutation
  obterEstadoPadrao(): void {
    this.permissoesUsuario = obterPermissoesUsuario();
    this.parametrosCrm = obterParametrosCrm();
    this.parametrosPortal = obterParametrosPortal();
    this.parametrosPos = obterParametrosPos();
    this.utilizaIntegracaoSalesForce = false;
    this.analiseDeCreditoAtiva = false;
  }

  @mutation
  atualizaParametrosCrm(ParametrosCrmDTO: ParametrosCrmDTO): void {
    this.parametrosCrmAtualizacao.tipoClienteBaseCentral.valor = ParametrosCrmDTO.TipoClienteBaseCentral;
    this.parametrosCrmAtualizacao.atualizarBaseCentralClientesPor.valor = ParametrosCrmDTO.AtualizarBaseCentralClientesPor;
    this.parametrosCrmAtualizacao.cadastroClientesCentral.valor = ParametrosCrmDTO.CadastroClientesCentral;
    this.parametrosCrmAtualizacao.exigeClasse.valor = ParametrosCrmDTO.ExigeClasse;
    this.parametrosCrmAtualizacao.exigeEmail.valor = ParametrosCrmDTO.ExigeEmail;
    this.parametrosCrmAtualizacao.exigeDataNascimento.valor = ParametrosCrmDTO.ExigeDataNascimento;
    this.parametrosCrmAtualizacao.exigeSexo.valor = ParametrosCrmDTO.ExigeSexo;
    this.parametrosCrmAtualizacao.exigeTelefone.valor = ParametrosCrmDTO.ExigeTelefone;
    this.parametrosCrmAtualizacao.exigeCelular.valor = ParametrosCrmDTO.ExigeCelular;
    this.parametrosCrmAtualizacao.exigeUf.valor = ParametrosCrmDTO.ExigeUf;
    this.parametrosCrmAtualizacao.exigeDocProspect.valor = ParametrosCrmDTO.ExigeDocProspect;
    this.parametrosCrmAtualizacao.exigeTelefoneProspect.valor = ParametrosCrmDTO.ExigeTelefoneProspect;
    this.parametrosCrmAtualizacao.exigeEmailProspect.valor = ParametrosCrmDTO.ExigeEmailProspect;
  }

  @action
  async obterParametrosCrm(): Promise<ParametrosCrmGravacao> {
    const obterParametrosCrmResult = await CrmClient.obterParametrosCrm();
    const parametrosCrmAtualizacao = obterParametrosCrmGravacaoMapping(obterParametrosCrmResult.ParametrosCrm);
    this.parametrosCrmAtualizacao = JSON.parse(JSON.stringify(parametrosCrmAtualizacao));
    return parametrosCrmAtualizacao;
  }

  @action
  async obterParametrosEmpresaCrm(): Promise<ParametrosEmpresaCrmGravacao> {
    const obterParametrosEmpresaCrmResult = await CrmClient.obterParametrosEmpresaCrm();
    const parametrosEmpresaCrmAtualizacao = obterParametrosEmpresaCrmGravacaoMapping(
      obterParametrosEmpresaCrmResult.ParametrosEmpresaCrm,
    );
    this.parametrosEmpresaCrmAtualizacao = JSON.parse(JSON.stringify(parametrosEmpresaCrmAtualizacao));
    return parametrosEmpresaCrmAtualizacao;
  }

  @action
  async salvarParametrosCrm(parametrosCrmAtualizacao: ParametrosCrmGravacao): Promise<void> {
    const atualizarParametrosCrmDTO = obterSalvarParametrosCrmDTO(parametrosCrmAtualizacao, this.parametrosEmpresaCrmAtualizacao);
    return CrmClient.atualizarParametrosCrm(atualizarParametrosCrmDTO);
  }
}
