import { createModule, mutation, action } from 'vuex-class-component';

import ErpAdminClient from '@/api/clients/erpAdmin';

import ApiConfig from '@/api/utils/apiConfig';

import AutenticacaoTypes, { Login } from '@/core/types/autenticacao';

const VuexModule = createModule({
  namespaced: 'autenticacao',
  strict: false,
});

export default class AutenticacaoModule extends VuexModule {
  idPortal = 0;
  isAutenticado = false;
  isRotaPos = false;

  @mutation
  obterEstadoPadrao() {
    this.isAutenticado = false;
    this.idPortal = 0;
    this.isRotaPos = false;
  }

  @action
  async loginPorToken(payload: Login): Promise<void> {
    this.obterEstadoPadrao();
    ApiConfig.reset();
    const produtos = AutenticacaoTypes.obterNomesProdutosApi();
    const loginPorTokenDTO = { Produtos: produtos, IdAmbienteProdutos: payload.idConfigAmbiente };

    return ErpAdminClient.executarLoginPorToken(loginPorTokenDTO, payload.token).then((loginPorTokenResult) => {
      this.isAutenticado = true;
      this.idPortal = loginPorTokenResult.Portal.Id;

      if (payload.appOrigem == 'LinxMicrovixPOS') this.isRotaPos = true;

      ApiConfig.setTokenJWT(loginPorTokenResult.TokenJWT);
      ApiConfig.setBaseUrls(loginPorTokenResult.Produtos);

      return Promise.resolve();
    });
  }
}
