
  /* eslint-disable */
  import { computed, defineComponent, onUnmounted, reactive, toRefs, watchEffect, ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';

  import { vxm } from '@/store/index';

  import Menu from '@/components/clientes/cadastro/Menu.vue';
  import Identificacao from '@/components/clientes/cadastro/identificacao/Identificacao.vue';
  import Endereco from '@/components/clientes/cadastro/endereco/Endereco.vue';
  import EnderecoAdicional from '@/components/clientes/cadastro/endereco/EnderecoAdicional.vue';
  import EnderecoCobranca from '@/components/clientes/cadastro/endereco/EnderecoCobranca.vue';
  import Contatos from '@/components/clientes/cadastro/contato/Contatos.vue';
  import ContatoAdicional from '@/components/clientes/cadastro/contato/ContatoAdicional.vue';
  import Comercial from '@/components/clientes/cadastro/comercial/Comercial.vue';
  import DadosBancariosCadastroSelecao from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancariosCadastroSelecao.vue';
  import DadosBancarios from '@/components/clientes/cadastro/comercial/dadosBancarios/DadosBancarios.vue';
  import Convenio from '@/components/clientes/cadastro/convenio/Convenio.vue';
  import DadosAdicionais from '@/components/clientes/cadastro/adicionais/DadosAdicionais.vue';
  import CamposAdicionais from '@/components/clientes/cadastro/camposAdicionais/CamposAdicionais.vue';
  import CadastroBiometrico from '@/components/clientes/cadastro/cadastroBiometrico/CadastroBiometrico.vue';
  import Politica from '@/components/clientes/cadastro/politica/Politica.vue';

  import MenuTypes, { Secao, idSecao } from '@/core/types/menu';
  import PessoaModel from '@/core/models/pessoa';
  import AlertsExtension from '@/utils/alerts';
  import PessoaEnum, { TipoCliente, TipoPessoa } from '@/core/enums/pessoa';
  import { BiometriaClienteCrm } from '@/api/contracts/crm/biometria/biometriaClienteCrm';

  export default defineComponent({
    name: 'CadastroClientes',
    components: {
      Menu,
      Identificacao,
      Endereco,
      EnderecoAdicional,
      EnderecoCobranca,
      Contatos,
      ContatoAdicional,
      Comercial,
      Convenio,
      DadosAdicionais,
      CamposAdicionais,
      CadastroBiometrico,
      Politica,
      DadosBancarios,
      DadosBancariosCadastroSelecao
    },
    props: {
      idCliente: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const state = reactive({
        secoesMenu: {
          identificacao: MenuTypes.obterSecao(idSecao.identificacao, 'Identificação', true),
          endereco: MenuTypes.obterSecao(idSecao.endereco, 'Endereço', true),
          enderecoAdicional: MenuTypes.obterSecao(idSecao.enderecoAdicional, 'Endereço Adicional', false),
          enderecoCobranca: MenuTypes.obterSecao(idSecao.enderecoCobranca, 'Endereço De Cobrança', true),
          contatos: MenuTypes.obterSecao(idSecao.contatos, 'Contato', true),
          contatoAdicional: MenuTypes.obterSecao(idSecao.contatoAdicional, 'Contato Adicional', false),
          comercial: MenuTypes.obterSecao(idSecao.comercial, 'Comercial/Financeiro', true),
          dadosBancarios: MenuTypes.obterSecao(idSecao.dadosBancarios, 'Dados Bancários', false),
          convenio: MenuTypes.obterSecao(idSecao.convenio, 'Convênio', false),
          adicionais: MenuTypes.obterSecao(idSecao.adicionais, 'Dados Adicionais', true),
          camposAdicionais: MenuTypes.obterSecao(idSecao.camposAdicionais, 'Campos Adicionais', false),
          cadastroBiometrico: MenuTypes.obterSecao(idSecao.cadastroBiometrico, 'Cadastro Biométrico', false),
          politica: MenuTypes.obterSecao(idSecao.politica, 'Política De Tratamento De Dados', true),
        },

        integraLinxIO: computed((): boolean => {
          return vxm.parametrosCommerce.linxIO;
        }),
        conveniadorIdentificacao: computed((): boolean => {
          return vxm.cadastro.pessoa.conveniadorIdentificacao.valor;
        }),
      });

      const isShowSecoesCadastroClientes = ref(false);

      const router = useRouter();
      const route = useRoute();
      const { validarPessoa, validarDocumento } = PessoaModel;

      const isRotaPos = vxm.autenticacao.isRotaPos;

      const listaSecoesMenu = computed((): Array<Secao> => {
        const listaSecoesMenu: Array<Secao> = [];
        Object.values(state.secoesMenu).forEach((secaoMenu) => listaSecoesMenu.push(secaoMenu));
        return listaSecoesMenu;
      });

      const isShowSecaoConvenio = computed((): boolean => {
        if (vxm.cadastro.pessoa.tipoCliente.valor && vxm.parametros.parametrosCrm.usaConvenios) {
          return (
            (vxm.cadastro.isPessoaFornecedor || 
            vxm.cadastro.isPessoaTransportador) && 
            vxm.cadastro.pessoa.conveniadorIdentificacao.valor
            || vxm.cadastro.isPessoaCliente ||
            vxm.cadastro.isPessoaAmbos ||
            vxm.cadastro.isPessoaAmbosTransportador
          );
        }
        return false;
      });

      const isShowContatoAdicional = computed((): boolean => {
        return !!vxm.cadastro.contatoAdicional.valor;
      });

      const isShowEnderecoAdicional = computed((): boolean => {
        return !!vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor;
      });

      const isShowCamposAdicionais = computed((): boolean => {
        return vxm.cadastro.dadosIniciaisParaCadastro.camposAdicionais.length > 0 ? true : false;
      });

      const isShowCadastroBiometrico = computed((): boolean => {
        return vxm.parametros.parametrosCrm.isCadastroBiometricoCliente;
      });

      const isShowDadosBancarios = computed((): boolean => {
        return vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor != null;
      });

      const bloqueioIntegracaoSalesForce = computed((): boolean => {
        if (vxm.cadastro.pessoa.tipoCliente.valor) {
          let tipoCliente = vxm.cadastro.pessoa.tipoCliente.valor as TipoCliente;

          return (
            vxm.parametros.utilizaIntegracaoSalesForce && (tipoCliente == TipoCliente.cliente || tipoCliente == TipoCliente.ambos)
          );
        }
        return false;
      });

      watchEffect(() => {
        state.secoesMenu.enderecoCobranca.visivel = vxm.cadastro.isEnderecoCobranca;
        state.secoesMenu.convenio.visivel = isShowSecaoConvenio.value;
        state.secoesMenu.camposAdicionais.visivel = isShowCamposAdicionais.value;
        state.secoesMenu.cadastroBiometrico.visivel = isShowCadastroBiometrico.value;
        state.secoesMenu.dadosBancarios.visivel = isShowDadosBancarios.value;
        if (vxm.cadastro.pessoa.tipoCliente.valor) verificarBloqueioIntegracaoSalesForce();
      });

      const exibirEnderecoCobranca = (): void => {
        const cepValido = (cep: any): boolean => {
          const regex = /^0+$/;
          return cep && !regex.test(cep.replace('-', ''));
        };

        vxm.cadastro.isEnderecoCobranca = cepValido(vxm.cadastro.pessoa.enderecoCobranca.cep.valor);
      };

      const tratarEnderecoCobranca = (): void => {
        if (!state.secoesMenu.enderecoCobranca.visivel) {
          vxm.cadastro.pessoa.enderecoCobranca.bairro.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.cep.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.cidade.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.complemento.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.estado.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.logradouro.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.numero.valor = '';
          vxm.cadastro.pessoa.enderecoCobranca.pais.valor = '';
        }
      };

      const cadastrarPessoa = (): void => {
        vxm.cadastro
          .cadastrarPessoa(vxm.cadastro.pessoa)
          .then(async (idPessoa) => {
            console.log('cadastrou', vxm.cadastro.pessoa);
            await vxm.cadastro.inserirAnexos(idPessoa);
            await vxm.cadastro.inserirDocs(idPessoa);
            await vxm.cadastro.inserirFotoPessoa(idPessoa);
            if (isShowCadastroBiometrico && vxm.cadastro.biometriaCapturada.length > 0) await atualizarBiometria(idPessoa);
            vxm.cadastro.cameraAberta = false;
            return;
          })
          .then(() => {
            if (isRotaPos) {
              try {
                // @ts-ignore
                dotNetMessage.show(
                  JSON.stringify({ Sucesso: true, AcaoRealizar: 'FecharCadastroCrm', Id: vxm.cadastro.pessoa.id.valor }),
                );
                AlertsExtension.exibirMensagemSucesso('Registro Cadastrado Com Sucesso', true);
              } catch (error) {
                AlertsExtension.exibirMensagemSucesso('Erro ao cadastrar cliente com origem do LinxMicrovixPOS: ' + error, false);
              }
              AlertsExtension.exibirMensagemSucesso('Registro Cadastrado Com Sucesso', true);
            } else {
              router.push({ name: 'ListagemClientes' });
              AlertsExtension.exibirMensagemSucesso('Registro Cadastrado Com Sucesso', true);
            }
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => vxm.loading.hideLoading());
      };

      const gerarAnaliseCredito = (): void => {
        vxm.loading.showLoading();
        vxm.cadastro
          .gerarAnaliseCredito(vxm.cadastro.pessoa.id.valor)
          .then((response) => {
            AlertsExtension.exibirMensagemSucesso('Análise de crédito realizada com Sucesso', true);
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => vxm.loading.hideLoading());
      };

      const atualizarPessoaBancos = (excluir: boolean): void => {
        vxm.loading.showLoading();

        vxm.cadastro
          .atualizarPessoaBancos({Pessoa: vxm.cadastro.pessoa, Excluir: excluir})
          .then(() => {
              AlertsExtension.exibirMensagemSucesso('Dados Bancários atualizados com sucesso!', false);
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      const atualizarPessoaPorSecaoCadastroParcial = (secaoCadastroParcial: string, excluir: boolean): void => {
        switch (secaoCadastroParcial) {
          case idSecao.dadosBancarios:
            atualizarPessoaBancos(excluir)
            break;
        
          default:
            break;
        }
      }

      const obterDadosIniciaisCadastroParcial = (): void => {
        switch (rotaCadastroParcial) {
          case idSecao.dadosBancarios:
            vxm.cadastro.abrirDadosBancariosConsiderandoContaAgendamento();
            break;
        
          default:
            break;
        }
      }

      const atualizarPessoa = (): void => {
        vxm.cadastro
          .atualizarPessoa(vxm.cadastro.pessoa)
          .then(async () => {
            if (vxm.cadastro.exlcuirFotoCadastro) await vxm.cadastro.excluirFoto(vxm.cadastro.pessoa.id.valor);
            await vxm.cadastro.inserirAnexos(vxm.cadastro.pessoa.id.valor);
            await vxm.cadastro.inserirDocs(vxm.cadastro.pessoa.id.valor);
            await vxm.cadastro.inserirFotoPessoa(vxm.cadastro.pessoa.id.valor);
            if (isShowCadastroBiometrico && vxm.cadastro.biometriaCapturada.length > 0)
              await atualizarBiometria(vxm.cadastro.pessoa.id.valor);
            vxm.cadastro.exlcuirFotoCadastro = false;
            vxm.cadastro.cameraAberta = false;
            return;
          })
          .then(() => {
            if (isRotaPos) {
              try {
                // @ts-ignore
                dotNetMessage.show(
                  JSON.stringify({ Sucesso: true, AcaoRealizar: 'FecharCadastroCrm', Id: vxm.cadastro.pessoa.id.valor }),
                );
                AlertsExtension.exibirMensagemSucesso('Registro Atualizado Com Sucesso', true);
                if (
                  vxm.parametros.analiseDeCreditoAtiva &&
                  vxm.cadastro.pessoa.tipoPessoa.valor === TipoPessoa.fisica &&
                  vxm.cadastro.pessoa.tipoCliente.valor !== TipoCliente.prospect
                )
                  exibirMensagemAnaliseCredito();
                else router.push({ name: 'ListagemClientes' });
              } catch (error) {
                AlertsExtension.exibirMensagemSucesso('Erro ao atualizar cliente com origem do LinxMicrovixPOS: ' + error, false);
              }
              AlertsExtension.exibirMensagemSucesso('Registro Atualizado Com Sucesso', true);
            } else {
              AlertsExtension.exibirMensagemSucesso('Registro Atualizado Com Sucesso', true);
              if (
                vxm.parametros.analiseDeCreditoAtiva &&
                vxm.cadastro.pessoa.tipoPessoa.valor === TipoPessoa.fisica &&
                vxm.cadastro.pessoa.tipoCliente.valor !== TipoCliente.prospect
              )
                exibirMensagemAnaliseCredito();
              else router.push({ name: 'ListagemClientes' });
            }
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      const atualizarBiometria = async (idPessoa: number) => {
        const biometriaClienteCrm: BiometriaClienteCrm = {
          IdCliente: idPessoa,
          Biometria: vxm.cadastro.biometriaCapturada,
        };
        await vxm.cadastro
          .atualizarBiometria(biometriaClienteCrm)
          .then((data) => AlertsExtension.exibirMensagemSucesso('Biometria Cadastrada Com Sucesso', true))
          .catch((error) => AlertsExtension.exibirMensagemErro('Ocorreu algum erro!', false));
      };

      const excluirCadastroParcial = (secaoCadastroParcial: string): void => {
        if (!secaoCadastroParcial) return;
        
        atualizarPessoaPorSecaoCadastroParcial(secaoCadastroParcial, true)
      }

      const salvarPessoa = (secaoCadastroParcial?: string): void => {
        if (secaoCadastroParcial) {
          atualizarPessoaPorSecaoCadastroParcial(secaoCadastroParcial, false)
          return;
        }

        const isPessoaValida = validarPessoa(
          vxm.cadastro.pessoa,
          vxm.parametros.parametrosCrm,
          vxm.parametros.parametrosPortal,
          vxm.parametros.permissoesUsuario,
          vxm.cadastro.pessoaObtidaSemAlteracoes,
          vxm.cadastro.isNovoCadastroPessoa,
          vxm.parametrosCommerce.linxIO,
        );

        if (isPessoaValida) {
          if (!vxm.cadastro.pessoa.endereco.estado.valor) setaEstadoEmpresaLogada();
          var isDocumentoValido = validarDocumento(vxm.cadastro.pessoa.documento);

          if (isDocumentoValido || vxm.cadastro.pessoa.isClienteInternacional.valor) {
            if (
              vxm.parametros.analiseDeCreditoAtiva &&
              vxm.cadastro.pessoa.tipoPessoa.valor === TipoPessoa.fisica &&
              vxm.cadastro.pessoa.tipoCliente.valor !== TipoCliente.prospect &&
              (vxm.cadastro.pessoa.pessoaFisica.nomeMae.valor == '' ||
                vxm.cadastro.pessoa.pessoaFisica.nomePai.valor === '' ||
                vxm.cadastro.pessoa.pessoaFisica.localTrabalho.valor === '' ||
                vxm.cadastro.pessoa.pessoaFisica.rendaMensal.valor === 0)
            ) {
              exibirMensagemCamposNecessários();
            } 
            else if (isRotaPos && !vxm.parametros.parametrosPos.permitirAtualizacaoDadosClientePos){
              AlertsExtension.exibirMensagemAlerta('Não foi possível atualizar o cliente, você não tem permissão para atualizar os dados do cliente diretamente no POS.\nVerificar as permissões de sua conta em parâmetros globais (POS)', false, 'Usuário sem permissão - LinxMicrovixPOS');
            } else executarSalvarPessoa();
          } else exibirMensagemConfirmacaoDocumentoInvalido();
        }
      };

      const setaEstadoEmpresaLogada = (): void => {
        const ufEmpresaLogado = vxm.cadastro.dadosIniciaisParaCadastro.estados.find(
          ({ descricao }) => vxm.parametros.parametrosCrm.estadoEmpresa === descricao,
        );

        if (ufEmpresaLogado) vxm.cadastro.pessoa.endereco.estado.valor = ufEmpresaLogado.id;
      };

      const exibirMensagemConfirmacaoDocumentoInvalido = (): void => {
        AlertsExtension.exibirMensagemPergunta(
          'Documento inválido',
          `${vxm.cadastro.pessoa.documento.label} inválido. Tem certeza que deseja continuar?`,
          (confirma) => {
            if (confirma) executarSalvarPessoa();
          },
        );
      };

      const exibirMensagemCamposNecessários = (): void => {
        AlertsExtension.exibirMensagemPergunta(
          'Atenção!',
          `Os campos ${vxm.cadastro.pessoa.pessoaFisica.nomeMae.label},
          ${vxm.cadastro.pessoa.pessoaFisica.nomePai.label},
          ${vxm.cadastro.pessoa.pessoaFisica.localTrabalho.label} e
          ${vxm.cadastro.pessoa.pessoaFisica.rendaMensal.label} são utilizados para a análise de crédito. Deseja prosseguir sem preencher todas essas informações?`,
          (confirma) => {
            if (confirma) executarSalvarPessoa();
          },
        );
      };

      const exibirMensagemAnaliseCredito = (): void => {
        AlertsExtension.exibirMensagemPergunta(
          'Análise de crédito',
          'Você alterou dados que influenciam na análise de crédito do crediário, deseja gerar uma nova agora?',
          (confirma) => {
            if (confirma) gerarAnaliseCredito();
            router.push({ name: 'ListagemClientes' });
          },
        );
      };

      const executarSalvarPessoa = (): void => {
        vxm.loading.showLoading();
        tratarEnderecoCobranca();
        vxm.cadastro.possuiFoto = false;
        vxm.cadastro.fotoCadastro = [];
        if (vxm.cadastro.isNovoCadastroPessoa) cadastrarPessoa();
        else atualizarPessoa();
      };

      const obterDadosIniciaisCadastro = async () => {
        vxm.loading.showLoading('Buscando informações para o cadastro ...');
        await vxm.cadastro
          .obterDadosIniciaisCadastro()
          .then((dadosIniciais) => {
            if (dadosIniciais) isShowSecoesCadastroClientes.value = true;
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      //MODAJOI-37214 (US 001) - Início
      const obterListagemFreteTipos = (): void => {
        if (!verificarVisibilidadeSecao(idSecao.comercial)) return

        vxm.loading.showLoading('Buscando informações do Tipo de Frete ...');
        vxm.notaSimplificada
          .obterListagemFreteTipos()
          .then((resultFreteTipos) => {
            if (resultFreteTipos) {
              vxm.notaSimplificada.dadosFreteTipos = resultFreteTipos;
            }
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };
      //MODAJOI-37214 (US 001) - Fim

      const obterPessoaParaAtualizar = async (idPessoa: any): Promise<void> => {
        vxm.loading.showLoading('Buscando cadastro existente ...');

        await vxm.cadastro
          .obterPessoaPorId(idPessoa)
          .then((pessoa) => {
            console.log('vxm.cadastro.obterPessoaPorId', pessoa);
            if (pessoa) {
              const labelTipoCliente = PessoaEnum.getDescricaoTipoCliente(pessoa.tipoCliente.valor as TipoCliente);
              if (PessoaModel.isPermitirAlterarPessoa(pessoa.tipoCliente.valor as number, vxm.parametros.permissoesUsuario)) {
                vxm.cadastro.pessoa = pessoa;
                vxm.cadastro.isNovoCadastroPessoa = false;
                exibirEnderecoCobranca();
              } else throw new Error(`Você não possui permissão para editar este ${labelTipoCliente}.`);
            }
          })
          .catch((error) => {
            AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      };

      const verificarBloqueioIntegracaoSalesForce = (): void => {
        if (bloqueioIntegracaoSalesForce.value) {
          var descricaoCliente = PessoaEnum.getDescricaoTipoCliente(TipoCliente.cliente);
          var descricaoAmbos = PessoaEnum.getDescricaoTipoCliente(TipoCliente.ambos);

          let textTipoCadastro = vxm.cadastro.isNovoCadastroPessoa
            ? `O cadastro do tipo ${descricaoCliente} e ${descricaoAmbos} está bloqueado`
            : `A alteração do cadastro do tipo ${descricaoCliente} e ${descricaoAmbos} está bloqueada`;

          AlertsExtension.exibirMensagemAlerta(
            `${textTipoCadastro} devido a integração do Sales Force estar ativada. <br><br>
            Em caso de dúvida entre em contato com a franqueadora`,
            false,
          );
        }
      };

      const idPessoa = Number(route.params.idPessoa);
      const rotaCadastroParcial = route.meta?.secaoCadastroParcial ?? null
      const codigoCliente = props.idCliente && !isNaN(parseInt(props.idCliente)) ? parseInt(props.idCliente) : Number(route.params.idCliente);

      const verificarVisibilidadeSecao = (idSecao: string, visibilidadeCondicional: boolean | null = null):boolean => {
        if (!!rotaCadastroParcial)
         return visibilidadeCondicional !== null ? rotaCadastroParcial === idSecao && visibilidadeCondicional : rotaCadastroParcial === idSecao;

        if (visibilidadeCondicional !== null) return visibilidadeCondicional;

        return true;
      }

      const verificarIdValido = (id?: number) => {
        return id !== undefined && id !== null && !isNaN(id);
      }

      obterDadosIniciaisCadastro().then(() => {
        const idPessoaValido = verificarIdValido(idPessoa);
        const codigoClienteValido = verificarIdValido(codigoCliente);

        const id = idPessoaValido ? idPessoa : (codigoClienteValido ? codigoCliente : null);

        if (id) obterPessoaParaAtualizar(id).then(() => obterDadosIniciaisCadastroParcial());
      });

      //MODAJOI-37214 (US 001)
      obterListagemFreteTipos();

      onUnmounted(() => {
        vxm.cadastro.obterEstadoPadrao();
      });

      return {
        ...toRefs(state),
        ...vxm,
        listaSecoesMenu,
        idSecao,
        excluirCadastroParcial,
        salvarPessoa,
        verificarVisibilidadeSecao,
        isShowSecaoConvenio,
        isShowContatoAdicional,
        isShowEnderecoAdicional,
        isShowSecoesCadastroClientes,
        isShowCamposAdicionais,
        isShowDadosBancarios,
        bloqueioIntegracaoSalesForce,
        isShowCadastroBiometrico,
        rotaCadastroParcial
      };
    },
  });
