import { vxm } from '@/store/index';

import FiltrosExtension from '@/utils/filtros';
import ParametrosTypes from '@/core/types/parametros';
import ParametrosMapping from '@/core/mappings/parametros';
import CamposTypes, { Campo, CampoMultiselect } from '@/core/types/campos';
import PessoaTypes, { Doc, Pessoa } from '@/core/types/clientes/pessoa/pessoa';
import PessoaCadastroTypes, {
  CategoriaFinanceira,
  Conveniador,
  DadosIniciaisCadastro,
  DadosIniciaisCadastroComParametros,
  Escolaridade,
  Estado,
  EstadoCivil,
  MotivoBloqueio,
  Pais,
  Parentesco,
  PlanoPagamento,
  Sexo,
  SubClasse,
  TabelaDePreco,
  TipoCliente,
  TipoDoc,
  TipoMoradia,
  Vendedor,
  RamoOpticoLaboratorio,
  ClassesFiscais,
  ClasseFiscalRegra,
  Banco,
  SerieFaturamento,
  GrupoEmpresarial,
  Empresa,
  TransportadoraIO,
  TipoRegistroProfissional,
  TipoContaBancaria,
  TipoChavePix,
} from '@/core/types/clientes/cadastro';

import { EnderecoViaCepResult } from '@/api/contracts/viaCep';
import { DadosCnpjReceitaResult } from '@/api/contracts/receitaWs';
import { RetornoObterCepPorEnderecoDTO } from '@/api/contracts/encontraCep';

import ClienteDadosCadastroTypes, {
  Classe,
  CadastroCamposAdicionais,
  CadastroCamposAdicionaisDetalhes,
} from '../types/clientes/cadastro';
import CnpjDadosTypes, { DadosCnpj } from '../types/clientes/pessoa/dadosCnpj';
import PessoaEnderecoTypes, { Endereco, EnderecoAdicional, EnderecoBuscaCep } from '../types/clientes/pessoa/endereco';
import PessoaComercialTypes, { PessoaBanco } from '../types/clientes/pessoa/comercial';
import PessoaContatoTypes, { ContatoAdicional } from '../types/clientes/pessoa/contato';
import PessoaCamposAdicionaisTypes, { PessoaCamposAdicionaisValores } from '../types/clientes/pessoa/camposAdicionais';
import PessoaConvenioTypes from '../types/clientes/pessoa/convenio';
import { ObterDadosIniciaisCadastroResult } from '@/api/contracts/crm/cadastro-completo/dadosIniciais';
import { ObterPessoaResult } from '@/api/contracts/crm/cadastro-completo/obterPessoa';
import {
  ContatoAdicionalDTO,
  EnderecoAdicionalDTO,
  SalvarPessoaDTO,
  CampoAdicionalValoresDTO,
  PessoaBancoDTO,
  PessoasClassesDTO,
} from '@/api/contracts/crm/cadastro-completo/salvarPessoa';
import { TipoCliente as TipoClienteEnum, TipoPessoa } from '../enums/pessoa';
import UtilsExtension from '@/utils/utils';
import PessoaDadosAdicionaisTypes, { Anexo } from '../types/clientes/pessoa/adicionais';
import { TipoChavePix as TipoChavePixEnum } from '../enums/cadastro';

const { obterPessoaCamposAdicionaisValores } = PessoaCamposAdicionaisTypes;
const { obterCadastroCamposAdicionais, obterCadastroCamposAdicionaisDetalhes } = ClienteDadosCadastroTypes;
const { removerMascara } = FiltrosExtension;
const { obterParametrosCrmMapping, obterPermissoesUsuarioMapping, obterParametrosPortalMapping, obterParametrosPosMapping } = ParametrosMapping;
const { obterParametros } = ParametrosTypes;
const { obterPessoa } = PessoaTypes;
const {
  obterClasse,
  obterSubClasse,
  obterConveniador,
  obterPais,
  obterPlanoPagamento,
  obterDadosIniciaisCadatro,
  obterDadosIniciaisCadastroComParametros,
  obterClassesFiscaisRegra,
  obterCampoTipoRegistroProfissional,
} = PessoaCadastroTypes;
const { obterCampoMultiselect, obterCampo } = CamposTypes;

class PessoaMapping {
  public static obterEnderecoMapping = (enderecoViaCepResult: EnderecoViaCepResult, estados: Array<Estado>): Endereco => {
    const { cep, bairro, logradouro, uf, localidade } = enderecoViaCepResult;

    const idEstado = estados.find(({ descricao }) => descricao === uf)?.id || '';

    const endereco = PessoaEnderecoTypes.obterEndereco(
      obterCampo(removerMascara(cep)),
      obterCampo(logradouro),
      obterCampo(''),
      obterCampo(''),
      obterCampo(bairro),
      obterCampo(localidade),
      obterCampo(idEstado, uf),
      obterCampo(0),
      obterCampo(''),
      obterCampo([]),
      obterCampo(0),
    );

    return endereco;
  };

  // public static obterEnderecoAdicionalMapping = (endereco: Endereco, enderecoAdicional?: EnderecoAdicional) => {
  //   const { cep, logradouro, numero, bairro, estado, cidade, complemento } = endereco;

  //   data.enderecoAdicional.valor.cep.valor = cep.valor;
  //   data.enderecoAdicional.valor.logradouro.valor = logradouro.valor;
  //   data.enderecoAdicional.valor.numero.valor = numero.valor;
  //   data.enderecoAdicional.valor.bairro.valor = bairro.valor;
  //   data.enderecoAdicional.valor.estado.valor = estado.valor;
  //   data.enderecoAdicional.valor.cidade.valor = cidade.valor;
  //   data.enderecoAdicional.valor.complemento.valor = complemento.valor;
  // };

  public static obterCepMapping = (buscaCepApiInterna: RetornoObterCepPorEnderecoDTO, estados: Array<Estado>): Endereco => {
    const { Bairro, CEP, Cidade, UF, Logradouro, Numero } = buscaCepApiInterna;

    const idEstado = estados.find(({ descricao }) => descricao === UF)?.id || '';

    const endereco = PessoaEnderecoTypes.obterEndereco(
      obterCampo(removerMascara(CEP)),
      obterCampo(Logradouro),
      obterCampo(Numero),
      obterCampo(''),
      obterCampo(Bairro),
      obterCampo(Cidade),
      obterCampo(idEstado, UF),
      obterCampo(0),
      obterCampo(0),
      obterCampo([]),
      obterCampo(0),
    );

    return endereco;
  };

  public static obterDadosCnpjReceitaMapping = (dadosCnpjResult: DadosCnpjReceitaResult, estados: Array<Estado>): DadosCnpj => {
    const { nome, fantasia, cep, logradouro, numero, complemento, bairro, municipio, uf, telefone, email, status } =
      dadosCnpjResult;

    const idEstado = estados.find(({ descricao }) => descricao === uf)?.id || '';
    const dadosCnpj = CnpjDadosTypes.obterDadosCnpj(
      obterCampo(nome),
      obterCampo(fantasia),
      obterCampo(cep),
      obterCampo(logradouro),
      obterCampo(numero),
      obterCampo(complemento),
      obterCampo(bairro),
      obterCampo(municipio),
      obterCampo(idEstado, uf),
      obterCampo(telefone),
      obterCampo(email),
      obterCampo(status),
    );

    return dadosCnpj;
  };

  public static obterDadosIniciaisCadastroMapping = (
    dadosIniciaisCadastroResult: ObterDadosIniciaisCadastroResult,
  ): DadosIniciaisCadastroComParametros => {
    const classes: Array<Classe> = [];
    const subClasses: Array<SubClasse> = [];
    const estadosCivis: Array<EstadoCivil> = [];
    const sexos: Array<Sexo> = [];
    const tiposClientes: Array<TipoCliente> = [];
    const escolaridades: Array<Escolaridade> = [];
    const estados: Array<Estado> = [];
    const tiposMoradias: Array<TipoMoradia> = [];
    const tiposDocs: Array<TipoDoc> = [];
    const paises: Array<Pais> = [];
    const tabelasDePrecos: Array<TabelaDePreco> = [];
    const vendedores: Array<Vendedor> = [];
    const planosPagamentos: Array<PlanoPagamento> = [];
    const conveniadores: Array<Conveniador> = [];
    const parentescos: Array<Parentesco> = [];
    const motivosBloqueios: Array<MotivoBloqueio> = [];
    const categoriasFinanceiras: Array<CategoriaFinanceira> = [];
    const ramoOpticoLaboratorios: Array<RamoOpticoLaboratorio> = [];
    const classesFiscais: Array<ClassesFiscais> = [];
    const classesFiscaisRegras: Array<ClasseFiscalRegra> = [];
    const camposAdicionais: Array<CadastroCamposAdicionais> = [];
    const bancos: Array<Banco> = [];
    const seriesFaturamento: Array<SerieFaturamento> = [];
    const gruposEmpresariais: Array<GrupoEmpresarial> = [];
    const empresas: Array<Empresa> = [];
    const transportadoras: Array<TransportadoraIO> = [];
    const tiposRegistrosProfissionais: Array<TipoRegistroProfissional> = [];
    const tiposContasBancarias: Array<TipoContaBancaria> = [];
    const tiposChavePix: Array<TipoChavePix> = [];

    const {
      Classes,
      SubClasses,
      EstadosCivis,
      Sexos,
      TiposCliente,
      Escolaridades,
      UFs,
      TiposMoradia,
      TiposDoc,
      Paises,
      Conveniadores,
      PlanosPagamento,
      TabelasPrecos,
      Vendedores,
      CategoriasFinanceiras,
      MotivosBloqueio,
      Parentescos,
      PermissoesUsuario,
      ParametrosCRM,
      ParametrosEmpresa,
      ParametrosPortal,
      ParametrosPos,
      PoliticaDeDados,
      RamoOpticoLaboratorios,
      ClassesFiscais,
      ClassesFiscaisRegra,
      CamposAdicionais,
      UtilizaIntegracaoSalesForce,
      AnaliseDeCreditoAtiva,
      Bancos,
      Series,
      GruposEmpresariais,
      Empresas,
      TransportadorasIO,
      TiposRegistrosProfissionais,
      TiposContasBancarias,
      TiposChavePix,
    } = dadosIniciaisCadastroResult;

    Classes.forEach(({ Id, Cor, Descricao }) => classes.push(obterClasse(Id, Descricao, Cor)));
    SubClasses.forEach(({ Id, Descricao, IdClasse }) => subClasses.push(obterSubClasse(Id, Descricao, IdClasse)));
    Paises.forEach(({ Id, Nome, Sigla }) => paises.push(obterPais(Id, Nome, Sigla)));
    Conveniadores.forEach(({ Id, NomeRazaoSocial, NomeFantasia }) =>
      conveniadores.push(obterConveniador(Id, NomeRazaoSocial, NomeFantasia)),
    );
    PlanosPagamento.forEach(({ Id, NomePlano, TipoPlano, FormaPgto }) =>
      planosPagamentos.push(obterPlanoPagamento(Id, NomePlano, TipoPlano, FormaPgto)),
    );
    EstadosCivis.forEach(({ Id, Descricao }) => estadosCivis.push(obterCampoMultiselect(Id, Descricao)));
    Sexos.forEach(({ Id, Descricao }) => sexos.push(obterCampoMultiselect(Id, Descricao)));
    CategoriasFinanceiras.forEach(({ Id, Descricao }) => categoriasFinanceiras.push(obterCampoMultiselect(Id, Descricao)));
    TabelasPrecos.forEach(({ Id, Descricao }) => tabelasDePrecos.push(obterCampoMultiselect(Id, Descricao)));
    Escolaridades.forEach(({ Id, Descricao }) => escolaridades.push(obterCampoMultiselect(Id, Descricao)));
    MotivosBloqueio.forEach(({ Id, Descricao }) => motivosBloqueios.push(obterCampoMultiselect(Id, Descricao)));
    Parentescos.forEach(({ Id, Descricao }) => parentescos.push(obterCampoMultiselect(Id, Descricao)));
    TiposCliente.forEach(({ Id, Descricao }) => tiposClientes.push(obterCampoMultiselect(Id, Descricao)));
    TiposMoradia.forEach(({ Id, Descricao }) => tiposMoradias.push(obterCampoMultiselect(Id, Descricao)));
    TiposDoc.forEach(({ Id, Descricao }) => tiposDocs.push(obterCampoMultiselect(Id, Descricao)));
    UFs.forEach(({ Id, Sigla }) => estados.push(obterCampoMultiselect(Id, Sigla)));
    Vendedores.forEach(({ Id, NomeVendedor }) => vendedores.push(obterCampoMultiselect(Id, NomeVendedor)));
    RamoOpticoLaboratorios.forEach(({ Id, NomeLaboratorio }) =>
      ramoOpticoLaboratorios.push(obterCampoMultiselect(Id, NomeLaboratorio)),
    );
    ClassesFiscais.filter(({ Excluido }) => !Excluido).forEach(({ Id, Descricao, Excluido }) =>
      classesFiscais.push(obterCampoMultiselect(Id, Descricao, Excluido)),
    );
    ClassesFiscaisRegra.forEach(({ Id, Contribuinte, Excluido, IdClasseFiscal, Uf }) =>
      classesFiscaisRegras.push(obterClassesFiscaisRegra(Id, Contribuinte, Excluido, IdClasseFiscal, Uf)),
    );
    GruposEmpresariais.forEach(({ Id, Descricao }) => gruposEmpresariais.push(obterCampoMultiselect(Id, Descricao)));

    CamposAdicionais.forEach(({ Id, NomeCampo, OrdemCampo, TipoCampo, Ativo, Detalhes }) => {
      const camposAdicionaisDetalhes = [] as Array<CadastroCamposAdicionaisDetalhes>;

      Detalhes.forEach((i) => {
        camposAdicionaisDetalhes.push(obterCadastroCamposAdicionaisDetalhes(i.Id, i.IdCampo, i.Descricao));
      });

      camposAdicionais.push(obterCadastroCamposAdicionais(Id, NomeCampo, OrdemCampo, TipoCampo, Ativo, camposAdicionaisDetalhes));
    });

    Bancos && Bancos.forEach(({ IdBanco, NomeBanco, Ativo }) => bancos.push(obterCampoMultiselect(IdBanco, NomeBanco, Ativo)));

    Series.forEach(({ SerieDescricao }) => {
      seriesFaturamento.push(obterCampoMultiselect(SerieDescricao, SerieDescricao));
    });

    Empresas.forEach(({ IdEmpresa, Nome }) => empresas.push(obterCampoMultiselect(IdEmpresa, `${IdEmpresa} - ${Nome}`)));

    TransportadorasIO?.forEach(({ Id, Descricao }) => transportadoras.push(obterCampoMultiselect(Id, Descricao)));

    TiposRegistrosProfissionais?.forEach(({ Id, Descricao, PermiteCadastrarSemInformarDocumento }) =>
      tiposRegistrosProfissionais.push(obterCampoTipoRegistroProfissional(Id, Descricao, PermiteCadastrarSemInformarDocumento)),
    );

    TiposContasBancarias?.forEach(({ Id, Descricao }) => tiposContasBancarias.push(obterCampoMultiselect(Id, Descricao)));
    TiposChavePix?.forEach(({ Id, Descricao }) => tiposChavePix.push(obterCampoMultiselect(Id, Descricao)));

    const dadosIniciaisCadastro = obterDadosIniciaisCadatro(
      classes,
      subClasses,
      estadosCivis,
      sexos,
      tiposClientes,
      escolaridades,
      estados,
      tiposMoradias,
      tiposDocs,
      paises,
      tabelasDePrecos,
      vendedores,
      planosPagamentos,
      conveniadores,
      parentescos,
      motivosBloqueios,
      categoriasFinanceiras,
      PoliticaDeDados,
      ramoOpticoLaboratorios,
      camposAdicionais,
      classesFiscais,
      classesFiscaisRegras,
      bancos,
      seriesFaturamento,
      gruposEmpresariais,
      empresas,
      [],
      transportadoras,
      tiposRegistrosProfissionais,
      tiposContasBancarias,
      tiposChavePix,
    );

    const permissoesUsuario = obterPermissoesUsuarioMapping(PermissoesUsuario);
    const parametrosCrm = obterParametrosCrmMapping(ParametrosCRM, ParametrosEmpresa);
    const parametrosPortal = obterParametrosPortalMapping(ParametrosPortal);
    const parametrosPos = obterParametrosPosMapping(ParametrosPos);
    const parametros = obterParametros(parametrosCrm, permissoesUsuario, parametrosPortal, parametrosPos);
    const dadosIniciaisCadastroComParametros = obterDadosIniciaisCadastroComParametros(
      dadosIniciaisCadastro,
      parametros,
      UtilizaIntegracaoSalesForce,
      AnaliseDeCreditoAtiva,
    );

    return dadosIniciaisCadastroComParametros;
  };

  public static obterPessoaMapping = (
    obterPessoaResult: ObterPessoaResult,
    dadosIniciaisCadastro: DadosIniciaisCadastro,
  ): Pessoa => {
    const { Pessoa } = obterPessoaResult;

    const dataNascimento = Pessoa.DadosPessoaFisica.DataNascimento
      ? FiltrosExtension.inserirMascaraData(Pessoa.DadosPessoaFisica.DataNascimento)
      : '';
    const dataEmissaoRG = Pessoa.DadosPessoaFisica.DataEmissaoIdentidadeCliente
      ? FiltrosExtension.inserirMascaraData(Pessoa.DadosPessoaFisica.DataEmissaoIdentidadeCliente)
      : '';

    const docs = [] as Array<Doc>;
    const campoDocs = obterCampo(docs);
    if (Pessoa.Docs) {
      Pessoa.Docs.forEach((docVM) => {
        const doc = PessoaTypes.ObterDoc(docVM.Id, docVM.NomeArquivo, docVM.Descricao, docVM.TipoDoc);
        campoDocs.valor.push(doc);
      });
    }

    const pessoaFisica = PessoaTypes.obterPessoaFisica(
      obterCampo(Pessoa.DadosPessoaFisica.IdentidadeCliente || ''),
      obterCampo(Pessoa.DadosPessoaFisica.OrgaoEmissaoIdentidadeCliente || ''),
      obterCampo(dataEmissaoRG),
      obterCampo(Pessoa.DadosPessoaFisica.IdEstadoCivil || ''),
      obterCampo(Pessoa.DadosPessoaFisica.Sexo !== null ? Pessoa.DadosPessoaFisica.Sexo : ''),
      obterCampo(Pessoa.DadosPessoaFisica.CartaoFidelidade || ''),
      obterCampo(Pessoa.DadosPessoaFisica.LocalDeTrabalho || ''),
      obterCampo(Pessoa.DadosPessoaFisica.AtividadeExercida || ''),
      obterCampo(Pessoa.DadosPessoaFisica.TempoNoAtualEmprego || ''),
      obterCampo(Pessoa.DadosPessoaFisica.RendaMensal || 0),
      obterCampo(Pessoa.DadosPessoaFisica.IdEscolaridade !== null ? Pessoa.DadosPessoaFisica.IdEscolaridade : ''),
      obterCampo(Pessoa.DadosPessoaFisica.NomeMae || ''),
      obterCampo(Pessoa.DadosPessoaFisica.NomePai || ''),
      obterCampo(Pessoa.DadosPessoaFisica.NomeConjuge || ''),
      obterCampo(Pessoa.DadosPessoaFisica.ReferenciasPessoais || ''),
      obterCampo(Pessoa.DadosPessoaFisica.NumeroDependentes || ''),
      obterCampo(Pessoa.DadosPessoaFisica.Naturalidade || ''),
      obterCampo(dataNascimento),
    );

    // TODO: Pessoa.DadosPessoaJuridica.OptanteSimplesEstadual -- está retornando uma string
    const pessoaJuridica = PessoaTypes.obterPessoaJuridica(
      obterCampo(Pessoa.DadosPessoaJuridica.OptanteSimplesEstadual !== 'False' || false),
      obterCampo(Pessoa.DadosPessoaJuridica.NumeroInscricaoSuframa || ''),
      obterCampo(Pessoa.DadosPessoaJuridica.InscricaoMunicipal || ''),
    );

    const fornecedor = PessoaTypes.obterFornecedor(
      obterCampo(Pessoa.DadosFornecedor.PagaDiferencialICMS || false),
      obterCampo(Pessoa.DadosFornecedor.BaseIcmsComDespesas || false),
      obterCampo(Pessoa.DadosFornecedor.BaseIpiComDespesas || false),
      obterCampo(Pessoa.DadosFornecedor.BaseIpiComFrete || false),
      obterCampo(Pessoa.DadosFornecedor.BaseIpiComSeguro || false),
      obterCampo(Pessoa.DadosFornecedor.BaseIpiComDescAcresPed || false),
      obterCampo(Pessoa.DadosFornecedor.LancaIcmsIpiEntrada || false),
      obterCampo(Pessoa.DadosFornecedor.PercentualDiferencialIcms || ''),
      obterCampo(Pessoa.DadosFornecedor.IdCategoriaFinanceira || ''),
    );

    let idEstado = dadosIniciaisCadastro.estados.find(({ descricao }) => descricao === Pessoa.EnderecoPessoa.UF)?.id || '';
    const endereco = PessoaEnderecoTypes.obterEndereco(
      obterCampo(Pessoa.EnderecoPessoa.CEP || ''),
      obterCampo(Pessoa.EnderecoPessoa.Logradouro || ''),
      obterCampo(Pessoa.EnderecoPessoa.Numero || ''),
      obterCampo(Pessoa.EnderecoPessoa.Complemento || ''),
      obterCampo(Pessoa.EnderecoPessoa.Bairro || ''),
      obterCampo(Pessoa.EnderecoPessoa.Cidade.NomeCidade, Pessoa.EnderecoPessoa.Cidade.NomeCidade || ''),
      obterCampo(idEstado, Pessoa.EnderecoPessoa.UF || ''),
      obterCampo(Pessoa.EnderecoPessoa.Pais.IdNfeBacenPais || ''),
      obterCampo(Pessoa.IdTipoMoradia != null ? Pessoa.IdTipoMoradia : ''),
      obterCampo([]),
      obterCampo(Pessoa.EnderecoPessoa.IdClasseFiscal),
    );

    const pessoasClasses = [] as Array<number>;
    const campoPessoasClasses = obterCampo(pessoasClasses);
    if (Pessoa.PessoasClasses) {
      Pessoa.PessoasClasses.forEach((classeVM) => {
        const classe = PessoaTypes.obterPessoasClasses(classeVM.Id, classeVM.IdClasse, classeVM.IdPessoa);
        campoPessoasClasses.valor.push(classe.idClasse);
      });
    }

    const enderecosAdicionais = [] as Array<EnderecoAdicional>;
    obterPessoaResult.Pessoa.EnderecosEntrega.forEach((enderecoAdicionalResult) => {
      const {
        Bairro,
        Primario,
        Numero,
        InscricaoEstadual,
        Endereco,
        Email,
        Complemento,
        Cidade,
        Celular,
        Cep,
        Descricao,
        DocumentoContribuinte,
        Id,
        PermiteExcluir,
        Telefone,
        Uf,
        UtilizaEnderecoDocumentoFiscal,
        ClasseFiscal,
        Excluido,
        Novo,
      } = enderecoAdicionalResult;

      const idEstadoEnderecoAdicional =
        Uf != 'EX' ? dadosIniciaisCadastro.estados.find(({ descricao }) => descricao === Uf)?.id || '' : 9999;

      const enderecoAdicional = {
        bairro: obterCampo(Bairro || ''),
        cep: obterCampo(Cep || ''),
        cidade: obterCampo(Cidade || ''),
        complemento: obterCampo(Complemento || ''),
        documento: obterCampo(DocumentoContribuinte || ''),
        email: obterCampo(Email || ''),
        estado: obterCampo(idEstadoEnderecoAdicional || '', Uf),
        isEnderecoPrincipal: obterCampo(Primario || false),
        numero: obterCampo(Numero || ''),
        razaoSocial: obterCampo(Descricao || ''),
        id: obterCampo(Id),
        logradouro: obterCampo(Endereco || ''),
        inscricaoEstadual: obterCampo(InscricaoEstadual || ''),
        telefonePrincipal: obterCampo(Celular || ''),
        telefoneSecundario: obterCampo(Telefone || ''),
        utilizaEnderecoDocumentoFiscal: obterCampo(UtilizaEnderecoDocumentoFiscal || false),
        idClasseFiscal: obterCampo(ClasseFiscal || ''),
        permiteExcluir: obterCampo(PermiteExcluir || false),
        excluido: obterCampo(Excluido || false),
        novo: obterCampo(Novo || false),
      } as EnderecoAdicional;

      enderecosAdicionais.push(enderecoAdicional);
    });

    endereco.enderecosAdicionais = obterCampo(enderecosAdicionais);

    const idBacenPaisEnderecoCobranca = dadosIniciaisCadastro.paises.find(({ descricao }) => {
      return descricao === Pessoa.EnderecoCobranca.Pais.Nome;
    })?.id;

    idEstado = dadosIniciaisCadastro.estados.find(({ descricao }) => descricao === Pessoa.EnderecoCobranca.UF)?.id || '';

    const enderecoCobranca = PessoaEnderecoTypes.obterEnderecoCobranca(
      obterCampo(Pessoa.EnderecoCobranca.CEP || ''),
      obterCampo(Pessoa.EnderecoCobranca.Logradouro || ''),
      obterCampo(Pessoa.EnderecoCobranca.Numero || ''),
      obterCampo(Pessoa.EnderecoCobranca.Complemento || ''),
      obterCampo(Pessoa.EnderecoCobranca.Bairro || ''),
      obterCampo(Pessoa.EnderecoCobranca.Cidade?.NomeCidade || '', Pessoa.EnderecoCobranca.Cidade?.NomeCidade || ''),
      obterCampo(idEstado || ''),
      obterCampo(idBacenPaisEnderecoCobranca || ''),
    );

    const dadosComerciaisPessoaBancos = [] as Array<PessoaBanco>;
    Pessoa.DadosComerciais.PessoaBancos.forEach(
      ({
        Agencia,
        AgenciaDigito,
        CodigoCliente,
        IdTipoContaBancaria,
        ContaCorrente,
        ContaCorrenteDigito,
        Fone,
        IdBanco,
        IdBancoCliente,
        NomeContato,
        Principal,
        ValorLimiteConta,
        Ativo,
        IsContaAgendamentoPagamento,
        NomeTitular,
        DocumentoTitular,
        IdTipoChavePix,
        ChavePix,
      }) => {
        const pessoaBanco = {
          isPix: obterCampo(!!IdTipoChavePix || false),
          idBancoCliente: obterCampo(IdBancoCliente || ''),
          idBanco: obterCampo(IdBanco || null),
          agencia: obterCampo(Agencia || ''),
          agenciaDigito: obterCampo(AgenciaDigito || ''),
          idTipoContaBancaria: obterCampo(IdTipoContaBancaria || 1),
          contaCorrente: obterCampo(ContaCorrente || ''),
          contaCorrenteDigito: obterCampo(ContaCorrenteDigito || ''),
          nomeContato: obterCampo(NomeContato || ''),
          fone: obterCampo(Fone || ''),
          valorLimiteConta: obterCampo(ValorLimiteConta || ''),
          codigoCliente: obterCampo(CodigoCliente || ''),
          isPrincipal: obterCampo(Principal),
          isContaAgendamentoPagamento: obterCampo(IsContaAgendamentoPagamento || false),
          isAtivo: obterCampo(Ativo),
          isNovoDadoBancario: obterCampo(false),
          nomeTitular: obterCampo(NomeTitular || ''),
          documentoTitular: obterCampo(DocumentoTitular || ''),
          idTipoChavePix: obterCampo(IdTipoChavePix || null),
          preencherNomeDocumentoCliente: obterCampo(false),
          chavePix: obterCampo(ChavePix || ''),
        } as PessoaBanco;

        dadosComerciaisPessoaBancos.push(pessoaBanco);
      },
    );
    const comercial = PessoaComercialTypes.obterDadosComerciais(
      obterCampo(Pessoa.DadosComerciais.FaturamentoLiberado === 'True' || false),
      obterCampo(Pessoa.DadosComerciais.IdMotivoBloqueioFaturamento || 0),
      obterCampo(Pessoa.DadosComerciais.MotivoBloqueioFaturamento || ''),
      obterCampo(Pessoa.DadosComerciais.ValorFaturamentoMinimo || 0),
      obterCampo(Pessoa.DadosComerciais.LimiteCreditoCrediario || 0),
      obterCampo(Pessoa.DadosComerciais.LimiteCreditoDinheiroCartao || 0),
      obterCampo(Pessoa.DadosComerciais.ValorTolerancia || 0),
      obterCampo(Pessoa.DadosComerciais.TipoRenovacaoSaldo || 0),
      obterCampo(Pessoa.DadosComerciais.PrazoValidadeLimite || 0),
      obterCampo(Pessoa.DadosComerciais.IdTabelaPrecos || ''),
      obterCampo(Pessoa.DadosComerciais.PrazoEntrega),
      obterCampo(Pessoa.DadosComerciais.UtilizaCorreiosSedex ? 1 : 0),
      obterCampo(Pessoa.DadosComerciais.RecebeNewsletter || false),
      obterCampo(Pessoa.DadosComerciais.Vendedores || []),
      obterCampo(Pessoa.DadosComerciais.PlanosPagamento || []),
      obterCampo(Pessoa.DadosComerciais.IdRamoOpticoLaboratorios || ''),
      obterCampo(Pessoa.DadosComerciais.IdFreteTiposPreferencial || ''), //MODAJOI-37214 (US 001)
      obterCampo(Pessoa.DadosComerciais.ObrigarPreenchimentoQuantidadeEspecie || false), //MODAJOI-37214 (US 002)
      obterCampo(dadosComerciaisPessoaBancos),
      obterCampo(Pessoa.DadosComerciais.SerieFaturamento),
      obterCampo(Pessoa.DadosComerciais.InformacoesComerciais),
      obterCampo(Pessoa.DadosComerciais.AceitaBoletoBancario ?? true),
      obterCampo(Pessoa.DadosComerciais.IdGrupoEmpresarial || 0),
      obterCampo(Pessoa.DadosComerciais.DestacaIcmsProprio || false),
      Pessoa.PessoasRelacionadas || [],
      obterCampo(''),
      obterCampo(Pessoa.DadosComerciais.AceitaProgramaFidelidade ?? true),
      obterCampo(Pessoa.DadosComerciais.PercentualMaximoDevolucao || 0),
    );

    const contatosAdicionais = obterCampo([] as Array<ContatoAdicional>);
    Pessoa.ContatosAdicionais.forEach(
      ({ Id, Celular, DataNascimento, Email, IdParentesco, Nome, Sexo, Telefone, Tipo, Observacoes, PermiteExcluir }) => {
        const dataNascimentoCA = DataNascimento ? FiltrosExtension.inserirMascaraData(DataNascimento) : '';
        contatosAdicionais.valor.push(
          PessoaContatoTypes.obterContatoAdicional(
            obterCampo(Id || ''),
            obterCampo(Nome || ''),
            obterCampo(Sexo || ''),
            obterCampo(IdParentesco || ''),
            obterCampo(dataNascimentoCA),
            obterCampo(Telefone || ''),
            obterCampo(Celular || ''),
            obterCampo(Email || ''),
            obterCampo(Tipo || ''),
            obterCampo(Observacoes || ''),
            obterCampo(PermiteExcluir ?? true),
          ),
        );
      },
    );

    const contatos = PessoaContatoTypes.obterContatos(
      obterCampo(Pessoa.Email || ''),
      obterCampo(Pessoa.Celular || vxm.parametros.parametrosCrm.foneDDD),
      obterCampo(Pessoa.Telefone || vxm.parametros.parametrosCrm.foneDDD),
      obterCampo(Pessoa.Telefone2 || vxm.parametros.parametrosCrm.foneDDD),
      obterCampo(Pessoa.Site || ''),
      contatosAdicionais,
    );

    const camposAdicionaisValores = [] as Array<PessoaCamposAdicionaisValores>;
    Pessoa.CamposAdicionaisValores.forEach(({ Id, IdCampo, ContentCampo, IdPessoa }) => {
      camposAdicionaisValores.push(
        obterPessoaCamposAdicionaisValores(obterCampo(Id), obterCampo(IdCampo), obterCampo(ContentCampo), obterCampo(IdPessoa)),
      );
    });

    /*const camposAdicionais = [] as Array<PessoaCamposAdicionais>;
    Pessoa.CamposAdicionais.forEach(({ Id, NomeCampo, OrdemCampo, TipoCampo, Ativo, Valores, Detalhes }) => {
      const camposAdicionaisDetalhes = obterCampo([] as Array<PessoaCamposAdicionaisDetalhes>);
      Detalhes.forEach((i) => {
        camposAdicionaisDetalhes.valor.push(
          obterPessoaCamposAdicionaisDetalhes(obterCampo(i.Id), obterCampo(i.IdCampo), obterCampo(i.Descricao)),
        );
      });

      const camposAdicionaisValores = obterCampo([] as Array<PessoaCamposAdicionaisValores>);
      Valores.forEach((i) => {
        camposAdicionaisValores.valor.push(
          obterPessoaCamposAdicionaisValores(
            obterCampo(i.Id),
            obterCampo(i.IdCampo),
            obterCampo(i.ContentCampo),
            obterCampo(i.IdPessoa),
          ),
        );
      });
      camposAdicionais.push(
        obterPessoaCamposAdicionais(
          obterCampo(Id),
          obterCampo(NomeCampo),
          obterCampo(OrdemCampo),
          obterCampo(TipoCampo),
          obterCampo(Ativo),
          camposAdicionaisValores,
          camposAdicionaisDetalhes,
        ),
      );
    });*/

    const convenioCliente = PessoaConvenioTypes.obterConvenioCliente(
      obterCampo(Pessoa.DadosConvenioCliente.CodigoConvenio || ''),
      obterCampo(Pessoa.DadosConvenioCliente.MatriculaConveniado || ''),
      obterCampo(Pessoa.DadosConvenioCliente.LimiteConvenio || 0),
      obterCampo(Pessoa.DadosConvenioCliente.ConvenioBloqueado || false),
    );

    const convenioFornecedor = PessoaConvenioTypes.obterConvenioFornecedor(
      obterCampo(Pessoa.DadosConvenioFornecedor.LimiteConvenio || 0),
      obterCampo(Pessoa.DadosConvenioCliente.ConvenioBloqueado || false),
      obterCampo(Pessoa.DadosConvenioFornecedor.DiaFechaConvenio || ''),
      obterCampo(Pessoa.DadosConvenioFornecedor.DiaCobraConvenio || ''),
    );

    const anexos = [] as Array<Anexo>;
    const campoAnexos = obterCampo(anexos);
    if (Pessoa.Anexos) {
      Pessoa.Anexos.forEach((anexoVM) => {
        const anexo = PessoaDadosAdicionaisTypes.ObterAnexo(anexoVM.Id, anexoVM.NomeAnexo, anexoVM.TamanhoAnexo);
        campoAnexos.valor.push(anexo);
      });
    }

    const dadosAdicionais = PessoaDadosAdicionaisTypes.ObterDadosAdicionais(
      campoAnexos,
      obterCampo(Pessoa.Observacoes || ''),
      obterCampo(Pessoa.ObservacoesPedidoCompra || ''),
      obterCampo(Pessoa.UrlRastreioIO || ''),
      obterCampo(Pessoa.CompraMinima || 0),
    );

    const isInscricaoEstadual =
      (Pessoa?.InscricaoEstadual !== 'ISENTO' && !!Pessoa?.InscricaoEstadual) ||
      (Pessoa?.ContribuinteICMS && Pessoa?.InscricaoEstadual === 'ISENTO');

    const pessoa = obterPessoa(
      obterCampo(Pessoa.Id || ''),
      obterCampo(Pessoa.Documento || ''),
      obterCampo(Pessoa.NomeRazaoSocial || ''),
      obterCampo(Pessoa.NomeFantasia || ''),
      obterCampo(Pessoa.DocumentoEstrangeiro || ''),
      obterCampo(Pessoa.InscricaoEstadual || ''),
      obterCampo(Pessoa.IdClassePreferencial || ''),
      obterCampo(Pessoa.IdSubclassePreferencial || ''),
      obterCampo(isInscricaoEstadual),
      obterCampo(Pessoa.Revendedor || false),
      obterCampo(Pessoa.ClienteInternacional || false),
      obterCampo(Pessoa.ContribuinteICMS || false),
      obterCampo(!Pessoa.CadastroDesativado || false),
      obterCampo(Pessoa.ClienteAnonimo || false),
      obterCampo(Pessoa.TipoCliente || ''),
      obterCampo(Pessoa.TipoPessoa || ''),
      pessoaFisica,
      pessoaJuridica,
      fornecedor,
      endereco,
      enderecoCobranca,
      contatos,
      comercial,
      convenioCliente,
      convenioFornecedor,
      dadosAdicionais,
      camposAdicionaisValores,
      obterCampo(Pessoa.Empresas || []),
      campoDocs,
      campoPessoasClasses,
      obterCampo(Pessoa.TransportadoraIO || ''),
      obterCampo(Pessoa.ReceberEmailMarketing || false),
      obterCampo(Pessoa.PercentualMaximoDevolucao || 0),
      obterCampo(Pessoa.RegistroProfissional || ''),
      obterCampo(Pessoa.IdTipoRegistroProfissional || ''),
      obterCampo(Pessoa.TransportadorIdentificacao || false),
      obterCampo(Pessoa.ConveniadorIdentificacao || false),
    );
    return pessoa;
  };

  public static obterChavePixPadrao = (chavePix: string, idTipoChavePix: number | null): string | null => {
    if (idTipoChavePix === TipoChavePixEnum.ContaBancaria) return null;

    switch (idTipoChavePix) {
      case TipoChavePixEnum.Celular:
      case TipoChavePixEnum.CpfCnpj:
        return FiltrosExtension.removerMascara(chavePix);
      case TipoChavePixEnum.ChaveAleatoria:
        return FiltrosExtension.removerCaracteresEspeciais(chavePix);
      default:
        return chavePix ?? null;
    }
  }

  public static obterPessoasBancoDTO = (pessoa: Pessoa): PessoaBancoDTO[] => {
    const pessoaBancosDTO = [] as Array<PessoaBancoDTO>;
    pessoa.comercial.bancos.valor.forEach(
      ({
        agencia,
        agenciaDigito,
        idTipoContaBancaria,
        contaCorrente,
        contaCorrenteDigito,
        fone,
        idBanco,
        isAtivo,
        isPrincipal,
        nomeContato,
        valorLimiteConta,
        idBancoCliente,
        isNovoDadoBancario,
        chavePix,
        documentoTitular,
        idTipoChavePix,
        isContaAgendamentoPagamento,
        isPix,
        nomeTitular,
      }) => {
        const isPixPadrao = isPix.valor && idTipoChavePix.valor !== TipoChavePixEnum.ContaBancaria;
        const chavePixPadrao = PessoaMapping.obterChavePixPadrao(chavePix.valor.toString(), idTipoChavePix.valor);

        const pessoaBancoDTO = {
          Agencia: isPixPadrao ? '' : UtilsExtension.obterSomenteNumeros(agencia.valor) || '',
          AgenciaDigito: isPixPadrao ? '' : UtilsExtension.obterSomenteNumeros(String(agenciaDigito.valor)) || '',
          IdTipoContaBancaria: isPixPadrao ? null : idTipoContaBancaria.valor,
          ContaCorrente: isPixPadrao ? '' : UtilsExtension.obterSomenteNumeros(String(contaCorrente.valor)) || '',
          ContaCorrenteDigito: isPixPadrao ? null : UtilsExtension.obterSomenteNumeros(String(contaCorrenteDigito.valor)) || null,
          Fone: fone.valor || '',
          IdBanco: isPixPadrao ? null : idBanco?.valor ? parseInt(idBanco?.valor?.toString()?.substring(0, 3)) || '' : null,
          NomeContato: nomeContato.valor || '',
          Principal: isPixPadrao ? false : isPrincipal.valor,
          Ativo: isAtivo.valor,
          ValorLimiteConta: isPixPadrao ? null : UtilsExtension.obterSomenteNumeros(String(valorLimiteConta.valor)) || null,
          NomeTitular: isPixPadrao ? '' : nomeTitular.valor?.trim() || '',
          DocumentoTitular: isPixPadrao ? '' : UtilsExtension.obterSomenteNumeros(documentoTitular.valor.toString()) || '',
          IsContaAgendamentoPagamento: isContaAgendamentoPagamento.valor,
          ChavePix: isPixPadrao ? chavePixPadrao : null,
          IdTipoChavePix: isPix.valor ? idTipoChavePix.valor : null,
        } as PessoaBancoDTO;

        if (!isNovoDadoBancario.valor) pessoaBancoDTO.IdBancoCliente = idBancoCliente.valor;

        pessoaBancosDTO.push(pessoaBancoDTO);
      },
    );

    return pessoaBancosDTO;
  };

  public static obterSalvarPessoaDTO = (pessoa: Pessoa, dadosIniciaisCadastro: DadosIniciaisCadastro): SalvarPessoaDTO => {
    const contatosAdicionaisDTO = [] as Array<ContatoAdicionalDTO>;
    pessoa.contatos.contatosAdicionais.valor.forEach(
      ({ nome, celular, parentesco, telefone, dataNascimento, email, sexo, tipo, observacoes }) => {
        let dataNascimentoContatoAdicional = null as Date | null;
        if (dataNascimento.valor) dataNascimentoContatoAdicional = UtilsExtension.convertStringToDate(dataNascimento.valor);

        const contatoAdicionalDTO = {
          Nome: nome.valor,
          Celular: celular.valor,
          DataNascimento: dataNascimentoContatoAdicional,
          Email: email.valor,
          IdParentesco: parentesco.valor ? parentesco.valor : null,
          IdSexo: sexo.valor ? sexo.valor : 0,
          Telefone: telefone.valor,
          Tipo: tipo.valor,
          Observacoes: observacoes.valor ? observacoes.valor : '',
        } as ContatoAdicionalDTO;

        contatosAdicionaisDTO.push(contatoAdicionalDTO);
      },
    );

    const enderecosAdicionaisDTO = [] as Array<EnderecoAdicionalDTO>;

    pessoa.endereco.enderecosAdicionais.valor.forEach((enderecoAdicional) => {
      const {
        id,
        logradouro,
        numero,
        bairro,
        cep,
        cidade,
        complemento,
        documento,
        email,
        estado,
        inscricaoEstadual,
        isEnderecoPrincipal,
        razaoSocial,
        telefonePrincipal,
        telefoneSecundario,
        utilizaEnderecoDocumentoFiscal,
        permiteExcluir,
        idClasseFiscal,
        excluido,
        novo,
      } = enderecoAdicional;

      const enderecoAdicionalDTO = {
        Id: id.valor,
        Bairro: bairro.valor,
        CEP: cep.valor,
        Cidade: cidade.valor,
        Complemento: complemento.valor,
        Documento: documento.valor,
        Email: email.valor,
        Endereco: logradouro.valor,
        InscricaoEstadual: inscricaoEstadual.valor,
        NomeRazaoSocial: razaoSocial.valor,
        Numero: numero.valor,
        Primario: isEnderecoPrincipal.valor,
        TelefonePrncipal: telefonePrincipal.valor,
        TelefoneSecundario: telefoneSecundario.valor,
        UF: estado.descricao,
        UtilizaEnderecoDocumentoFiscal: utilizaEnderecoDocumentoFiscal.valor,
        ClasseFiscal: idClasseFiscal.valor,
        PermiteExcluir: permiteExcluir.valor,
        Excluido: excluido.valor,
        Novo: novo.valor,
      } as EnderecoAdicionalDTO;

      enderecosAdicionaisDTO.push(enderecoAdicionalDTO);
    });

    const idsVendedoresDTO = [] as Array<number>;
    pessoa.comercial.vendedores.valor.forEach((valor) => idsVendedoresDTO.push(Number(valor)));

    const idsPlanosPagamentosDTO = [] as Array<number>;
    pessoa.comercial.planosPagamentos.valor.forEach((valor) => idsPlanosPagamentosDTO.push(Number(valor)));

    let dataEmissaoIdentidadePessoa = null as Date | null;
    if (pessoa.pessoaFisica.dataEmissaoRg.valor)
      dataEmissaoIdentidadePessoa = UtilsExtension.convertStringToDate(pessoa.pessoaFisica.dataEmissaoRg.valor);

    let dataNascimentoPessoa = null as Date | null;
    if (pessoa.pessoaFisica.dataNascimento.valor)
      dataNascimentoPessoa = UtilsExtension.convertStringToDate(pessoa.pessoaFisica.dataNascimento.valor);

    if (pessoa.isClienteInternacional.valor) pessoa.tipoPessoa.valor = TipoPessoa.juridica;

    let limiteConvenio = 0;
    let bloquearConvenio = false;

    if (pessoa.tipoCliente.valor === TipoClienteEnum.cliente || 
      ((pessoa.tipoCliente.valor === TipoClienteEnum.ambos || pessoa.tipoCliente.valor === TipoClienteEnum.ambosTransportador) && !pessoa.conveniadorIdentificacao.valor)) {
      limiteConvenio = pessoa.convenioCliente.limite.valor ? pessoa.convenioCliente.limite.valor : 0;
      bloquearConvenio = pessoa.convenioCliente.isBloquearConveniado.valor;
    }

    if (pessoa.tipoCliente.valor === TipoClienteEnum.fornecedor || pessoa.conveniadorIdentificacao.valor) {
      limiteConvenio = pessoa.convenioFornecedor.limitePadrao.valor ? pessoa.convenioFornecedor.limitePadrao.valor : 0;
      bloquearConvenio = pessoa.convenioFornecedor.isBloquearConveniador.valor;
    }

    if (pessoa.endereco.estado.valor) {
      const estadoSelecionado = dadosIniciaisCadastro.estados.find(({ id }) => pessoa.endereco.estado.valor === id);
      if (estadoSelecionado) pessoa.endereco.estado.descricao = estadoSelecionado.descricao;
      else pessoa.endereco.estado.descricao = 'EX';
    }

    if (pessoa.endereco.pais.valor) {
      const paisSelecionado = dadosIniciaisCadastro.paises.find(({ id }) => pessoa.endereco.pais.valor === id);
      if (paisSelecionado) pessoa.endereco.pais.descricao = paisSelecionado.descricao;
    }

    if (pessoa.enderecoCobranca.pais.valor) {
      const paisSelecionado = dadosIniciaisCadastro.paises.find(({ id }) => pessoa.enderecoCobranca.pais.valor === id);
      if (paisSelecionado) pessoa.enderecoCobranca.pais.descricao = paisSelecionado.descricao;
    }

    if (pessoa.enderecoCobranca.estado.valor) {
      const estadoSelecionado = dadosIniciaisCadastro.estados.find(({ id }) => pessoa.enderecoCobranca.estado.valor === id);
      if (estadoSelecionado) pessoa.enderecoCobranca.estado.descricao = estadoSelecionado.descricao;
      else pessoa.enderecoCobranca.estado.descricao = 'EX';
    }

    const camposAdicionaisValoresDTO = [] as Array<CampoAdicionalValoresDTO>;
    pessoa.camposAdicionaisValores.forEach(({ id, idCampo, contentCampo, idPessoa }) => {
      const campoAdicionalValorDTO = {
        Id: id.valor,
        IdCampo: idCampo.valor,
        ContentCampo: contentCampo.valor,
        IdPessoa: idPessoa.valor,
      } as CampoAdicionalValoresDTO;

      camposAdicionaisValoresDTO.push(campoAdicionalValorDTO);
    });

    const pessoaBancosDTO = PessoaMapping.obterPessoasBancoDTO(pessoa);

    const idsClassesDTO = [] as Array<PessoasClassesDTO>;
    pessoa.pessoasClasses.valor.forEach((valor) => {
      const classe: PessoasClassesDTO = {
        Id: 0,
        IdClasse: 0,
        IdPessoa: 0,
      };

      classe.IdClasse = valor;
      idsClassesDTO.push(classe);
    });

    const pessoaDTO = {
      IdTipoPessoa: pessoa.tipoPessoa.valor,
      IdTipoCliente: pessoa.tipoCliente.valor,
      Id: pessoa.id.valor,
      Site: pessoa.contatos.site.valor ? pessoa.contatos.site.valor : null,
      Documento: pessoa.documento.valor,
      NomeRazaoSocial: pessoa.nome.valor,
      NomeFantasia: pessoa.nomeFantasia.valor,
      Email: pessoa.contatos.email.valor,
      Telefone: pessoa.contatos.telefonePrincipal.valor.length == 2 ? '' : pessoa.contatos.telefonePrincipal.valor,
      Telefone2: pessoa.contatos.telefoneSecundario.valor.length == 2 ? '' : pessoa.contatos.telefoneSecundario.valor,
      Celular: pessoa.contatos.celular.valor.length == 2 ? '' : pessoa.contatos.celular.valor,
      DocumentoEstrangeiro: pessoa.isClienteInternacional.valor ? pessoa.documentoEstrangeiro.valor : null,
      InscricaoEstadual:
        pessoa.inscricaoEstadual.valor.toUpperCase() === 'ISENTO'
          ? pessoa.inscricaoEstadual.valor
          : removerMascara(pessoa.inscricaoEstadual.valor),
      Revendedor: pessoa.isRevendedor.valor,
      IdClassePreferencial: pessoa.classe.valor ? pessoa.classe.valor : null,
      IdSubclassePreferencial: pessoa.subclasse.valor ? pessoa.subclasse.valor : null,
      ClienteAnonimo: pessoa.isAnonimo.valor,
      ContribuinteICMS: pessoa.isContribuinteIcms.valor ? pessoa.isContribuinteIcms.valor : null,
      CadastroDesativado: !pessoa.isAtivo.valor,
      ClienteInternacional: pessoa.isClienteInternacional.valor,
      IdentidadeCliente: pessoa.pessoaFisica.rg.valor,
      DataEmissaoIdentidadeCliente: dataEmissaoIdentidadePessoa,
      OrgaoEmissaoIdentidadeCliente: pessoa.pessoaFisica.orgaoEmissor.valor,
      DataNascimento: dataNascimentoPessoa,
      IdEstadoCivil: pessoa.pessoaFisica.estadoCivil.valor ? pessoa.pessoaFisica.estadoCivil.valor : 0,
      IdSexo: pessoa.pessoaFisica.sexo.valor ? pessoa.pessoaFisica.sexo.valor : 0,
      CartaoFidelidade: pessoa.pessoaFisica.cartaoFidelidade.valor,
      LocalDeTrabalho: pessoa.pessoaFisica.localTrabalho.valor,
      AtividadeExercida: pessoa.pessoaFisica.atividadeExercida.valor,
      TempoNoAtualEmprego: pessoa.pessoaFisica.tempoEmpregoAtual.valor,
      RendaMensal: pessoa.pessoaFisica.rendaMensal.valor ? Number(pessoa.pessoaFisica.rendaMensal.valor) : null,
      IdEscolaridade: pessoa.pessoaFisica.escolaridade.valor !== '' ? pessoa.pessoaFisica.escolaridade.valor : null,
      NomeMae: pessoa.pessoaFisica.nomeMae.valor,
      NomePai: pessoa.pessoaFisica.nomePai.valor,
      NomeConjuge: pessoa.pessoaFisica.nomeConjuge.valor,
      ReferenciasPessoais: pessoa.pessoaFisica.referenciasPessoais.valor,
      NumeroDependentes: pessoa.pessoaFisica.quantidadeDependentes.valor ? pessoa.pessoaFisica.quantidadeDependentes.valor : null,
      Naturalidade: pessoa.pessoaFisica.naturalidade.valor,
      OptanteSimplesEstadual: pessoa.pessoaJuridica.isOptanteSimplesEstadual.valor,
      NumeroInscricaoSuframa: pessoa.pessoaJuridica.inscricaoSuframa.valor
        ? Number(pessoa.pessoaJuridica.inscricaoSuframa.valor)
        : null,
      InscricaoMunicipal: pessoa.pessoaJuridica.inscricaoMunicipal.valor,
      PagaDiferencialIcms: pessoa.fornecedor.isDiferencialIcms.valor,
      PercentualDiferencialIcms: pessoa.fornecedor.diferencialIcms.valor ? pessoa.fornecedor.diferencialIcms.valor : 0,
      BaseIcmsComDespesas: pessoa.fornecedor.isSomaDespesasParaIcmsEmNf.valor,
      BaseIpiComDespesas: pessoa.fornecedor.isSomaDespesasParaIpiEmNf.valor,
      BaseIpiComFrete: pessoa.fornecedor.isSomaFreteParaIpiEmNf.valor,
      BaseIpiComSeguro: pessoa.fornecedor.isSomaSeguroParaIpiEmNf.valor,
      BaseIpiComDescAcresPed: pessoa.fornecedor.isDescontosAcrescimosFornecedoresIpiPedidosDeCompra.valor,
      LancaIcmsIpiEntrada: pessoa.fornecedor.isLancaIcmsIpiEntradaDevolucaoCompras.valor,
      IdCategoriaFinanceira: pessoa.fornecedor.categoriaFinanceira.valor ? pessoa.fornecedor.categoriaFinanceira.valor : null,
      CepCliente: pessoa.endereco.cep.valor,
      RuaCliente: pessoa.endereco.logradouro.valor,
      NumeroCliente: pessoa.endereco.numero.valor,
      ComplementoCliente: pessoa.endereco.complemento.valor,
      BairroCliente: pessoa.endereco.bairro.valor,
      CidadeCliente: pessoa.endereco.cidade.valor,
      UfCliente: pessoa.endereco.estado.descricao,
      IdClasseFiscal: pessoa.endereco.idClasseFiscal.valor,
      IdBacenPaisCliente: Number(pessoa.endereco.pais.valor),
      IdTipoMoradia: pessoa.endereco.tipoMoradia.valor !== '' ? pessoa.endereco.tipoMoradia.valor : null,
      CepCobranca: pessoa.enderecoCobranca.cep.valor,
      RuaCobranca: pessoa.enderecoCobranca.logradouro.valor,
      NumeroCobranca: pessoa.enderecoCobranca.numero.valor,
      ComplementoCobranca: pessoa.enderecoCobranca.complemento.valor,
      BairroCobranca: pessoa.enderecoCobranca.bairro.valor,
      CidadeCobranca: pessoa.enderecoCobranca.cidade.valor,
      UfCobranca: pessoa.enderecoCobranca.estado.descricao,
      PaisCobranca: pessoa.enderecoCobranca.pais.descricao,
      ContatosAdicionais: contatosAdicionaisDTO,
      FaturamentoLiberado: pessoa.comercial.isFaturamentoLiberado.valor,
      IdMotivoBloqueioFaturamento: pessoa.comercial.idMotivoBloqueioFaturamento.valor
        ? Number(pessoa.comercial.idMotivoBloqueioFaturamento.valor)
        : null,
      MotivoBloqueioFaturamento: pessoa.comercial.motivoBloqueioFaturamento.valor
        ? pessoa.comercial.motivoBloqueioFaturamento.valor
        : null,
      ValorFaturamentoMinimo: pessoa.comercial.faturamentoMinimo.valor ? Number(pessoa.comercial.faturamentoMinimo.valor) : null,
      LimiteCreditoCrediario:
        pessoa.comercial.limiteCreditoCrediario.valor || pessoa.comercial.limiteCreditoCrediario.valor == 0
          ? Number(pessoa.comercial.limiteCreditoCrediario.valor)
          : null,
      LimiteCreditoDinheiroCartao:
        pessoa.comercial.limiteCreditoDinheiroCartao.valor || pessoa.comercial.limiteCreditoDinheiroCartao.valor == 0
          ? Number(pessoa.comercial.limiteCreditoDinheiroCartao.valor)
          : null,
      ValorTolerancia:
        pessoa.comercial.valorTolerancia.valor || pessoa.comercial.valorTolerancia.valor == 0
          ? Number(pessoa.comercial.valorTolerancia.valor)
          : null,
      TipoRenovacaoSaldo:
        pessoa.comercial.tipoRenovacaoSaldo.valor || pessoa.comercial.tipoRenovacaoSaldo.valor == 0
          ? Number(pessoa.comercial.tipoRenovacaoSaldo.valor)
          : null,
      PrazoValidadeLimite:
        pessoa.comercial.prazoValidadeLimite.valor || pessoa.comercial.prazoValidadeLimite.valor == 0
          ? Number(pessoa.comercial.prazoValidadeLimite.valor)
          : null,
      IdTabelaPrecos: pessoa.comercial.tabelaDePreco.valor ? pessoa.comercial.tabelaDePreco.valor : null,
      PrazoEntrega: pessoa.comercial.prazoEntrega.valor ? pessoa.comercial.prazoEntrega.valor : null,
      UtilizaCorreiosSedex: pessoa.comercial.utilizaCorreiosSedex.valor,
      RecebeNewsletter: pessoa.comercial.isRecebeNewsletter.valor,
      IdsVendedores: idsVendedoresDTO,
      IdsPlanos: idsPlanosPagamentosDTO,
      IdConveniador: pessoa.convenioCliente.conveniador.valor ? pessoa.convenioCliente.conveniador.valor : null,
      MatriculaConveniado: pessoa.convenioCliente.matricula.valor,
      LimiteConvenio: limiteConvenio,
      BloquearConvenio: bloquearConvenio,
      DiaCobrancaConvenio: pessoa.convenioFornecedor.diaCobranca.valor ? pessoa.convenioFornecedor.diaCobranca.valor : null,
      DiaFechamentoConvenio: pessoa.convenioFornecedor.diaFechamento.valor ? pessoa.convenioFornecedor.diaFechamento.valor : null,
      Observacoes: pessoa.dadosAdicionais.observacoes.valor,
      ObservacoesPedidoCompra: pessoa.dadosAdicionais.observacoesPedidoCompra.valor,
      UrlRastreioIO: pessoa.dadosAdicionais.urlRastreioIO.valor,
      CompraMinima: pessoa.dadosAdicionais.compraMinima.valor,
      Obs: '',
      EnderecosEntrega: enderecosAdicionaisDTO,
      IdRamoOpticoLaboratorios: pessoa.comercial.ramoOpticoLaboratorio.valor
        ? pessoa.comercial.ramoOpticoLaboratorio.valor
        : null,
      CamposAdicionaisValores: camposAdicionaisValoresDTO,
      IdFreteTiposPreferencial: pessoa.comercial.idFreteTiposPreferencial.valor
        ? pessoa.comercial.idFreteTiposPreferencial.valor
        : null, //MODAJOI-37214 (US 001)
      ObrigarPreenchimentoQuantidadeEspecie: pessoa.comercial.obrigarPreenchimentoQuantidadeEspecie.valor, //MODAJOI-37214 (US 002)
      PessoaBancos: pessoaBancosDTO,
      SerieFaturamento: pessoa.comercial.serieFaturamento.valor,
      InformacoesComerciais: pessoa.comercial.informacoesComerciais.valor,
      AceitaBoletoBancario: pessoa.comercial.aceitaBoletoBancario.valor,
      IdGrupoEmpresarial: pessoa.comercial.idGrupoEmpresarial.valor,
      IdEmpresas: pessoa.empresas.valor,
      DestacaIcmsProprio: pessoa.comercial.destacaIcmsProprio.valor,
      PessoasClasses: idsClassesDTO,
      IdTransportadoraIO: pessoa.transportadoraIO.valor,
      ReceberEmailMarketing: pessoa.receberEmailMarketing.valor,
      PessoasRelacionadas: pessoa.comercial.listaCnpjsVinculados,
      AceitaProgramaFidelidade: pessoa.comercial.aceitaProgramaFidelidade.valor,
      PercentualMaximoDevolucao:
        pessoa.comercial.percentualMaximoDevolucao.valor || pessoa.comercial.percentualMaximoDevolucao.valor == 0
          ? Number(pessoa.comercial.percentualMaximoDevolucao.valor)
          : 0,
      RegistroProfissional: pessoa.registroProfissional.valor,
      IdTipoRegistroProfissional: pessoa.idTipoRegistroProfissional.valor,
      TransportadorIdentificacao: pessoa.transportadorIdentificacao.valor,
      ConveniadorIdentificacao: pessoa.conveniadorIdentificacao.valor,
    } as SalvarPessoaDTO;

    return pessoaDTO;
  };

  public static obterSalvarPessoaBancosDTO = (pessoa: Pessoa): SalvarPessoaDTO => {
    const pessoaBancosDTO = PessoaMapping.obterPessoasBancoDTO(pessoa);

    const pessoaDTO = {
      IdTipoCliente: pessoa.tipoCliente.valor,
      Id: pessoa.id.valor,
      CadastroDesativado: !pessoa.isAtivo.valor,
      PessoaBancos: pessoaBancosDTO,
    } as SalvarPessoaDTO;

    return pessoaDTO;
  };
}

export default PessoaMapping;
