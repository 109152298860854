import { createModule, mutation, action } from 'vuex-class-component';

import { Pessoa } from '@/core/types/clientes/pessoa/pessoa';

import CrmClient from '@/api/clients/crm';
import ViaCepCliente from '@/api/clients/viaCep';
import DadosReceitaWs from '@/api/clients/receitaWs';

import PessoaTypes from '@/core/types/clientes/pessoa/pessoa';
import PessoaContatoTypes from '@/core/types/clientes/pessoa/contato';
import PessoaCadastroTypes, {
  ClassesFiscais,
  DadosIniciaisCadastroComParametros,
  DocParaInserir,
  Estado,
} from '@/core/types/clientes/cadastro';
import PessoaMapping from '@/core/mappings/pessoa';
import CamposTypes, { Campo } from '@/core/types/campos';
import { DadosCnpj } from '@/core/types/clientes/pessoa/dadosCnpj';
import PessoaEnderecoTypes, { Endereco, EnderecoAdicional, EnderecoBuscaCep } from '@/core/types/clientes/pessoa/endereco';
import { ContatoAdicional, ContatoAdicionalMultiselect } from '@/core/types/clientes/pessoa/contato';
import { vxm } from '@/store';
import { FileWithPath } from 'file-selector';
import { ApiError, ApiErrorContract } from '@/api/utils/handler';
import { ObterCepPorEnderecoDTO, RetornoObterCepPorEnderecoDTO } from '@/api/contracts/encontraCep';
import PessoaModel from '@/core/models/pessoa';
import AlertsExtension from '@/utils/alerts';
import Utils from '@/utils/utils';
import { ObterUsuariosSupervisoresResult } from '@/api/contracts/crm/cadastro-completo/usuariosSupervisores';
import {
  ControleClicouEmSimParaDesmarcar,
  PessoaBanco,
  PropClicouEmSimParaDesmarcar,
} from '@/core/types/clientes/pessoa/comercial';
import { TipoCliente } from '@/core/enums/pessoa';
import { Parametros, ParametrosCrm, ParametrosPortal } from '@/core/types/parametros';
import { BiometriaClienteCrm } from '@/api/contracts/crm/biometria/biometriaClienteCrm';
import { AtualizarPessoaBancoPayload } from '@/api/contracts/crm/cadastro-completo/salvarPessoa';

const {
  obterEnderecoMapping,
  obterDadosIniciaisCadastroMapping,
  obterPessoaMapping,
  obterSalvarPessoaDTO,
  obterSalvarPessoaBancosDTO,
  obterDadosCnpjReceitaMapping,
} = PessoaMapping;
const { obterNovaPessoa } = PessoaTypes;
const { obterNovoEnderecoAdicional } = PessoaEnderecoTypes;
const { obterDadosIniciaisCadatro } = PessoaCadastroTypes;
const { obterCampo, obterCampoMultiselect } = CamposTypes;
const { obterNovoContatoAdicional } = PessoaContatoTypes;

const VuexModule = createModule({
  namespaced: 'clientes',
  strict: false,
  enableLocalWatchers: true,
});

export default class ClientesModule extends VuexModule {
  //#region State

  pessoa = obterNovaPessoa();

  dadosIniciaisParaCadastro = obterDadosIniciaisCadatro();
  isNovoCadastroPessoa = true;
  isEnderecoCobranca = false;
  pessoaObtidaSemAlteracoes = null as Pessoa | null;

  contatoAdicional = obterCampo(null) as Campo<ContatoAdicional | null>;
  contatoAdicionalSelecionado = obterCampo(null) as Campo<ContatoAdicional | null>;
  isNovoCadastroContatoAdicional = true;
  isContatoAdicionalAlterado = false;

  componenteEnderecoAdicional = {
    enderecoAdicional: obterCampo(null) as Campo<EnderecoAdicional | null>,
    ultimoCepEnviadoViaCep: '',
    isCampoCidadeDisabled: false,
    isCampoEstadoDisabled: false,
  };

  componenteDadosBancarios = {
    pessoaBancoSelecionado: obterCampo(null) as Campo<PessoaBanco | null>,
    bloquearAdicaoDadosBancarios: false,
  };

  anexosParaInserir = [] as FileWithPath[];
  docsParaInserir = [] as DocParaInserir[];
  fotoAtual? = '';
  fotoNovaParaInserir?: FileWithPath | undefined = undefined;
  fotoNovaParaExibir? = '';
  modalEncontraCep = false;
  modalEncontraCepCobranca = false;
  modalEncontraCepAdicional = false;
  isCampoCidadeDisabled = false;
  isCampoEstadoDisabled = false;
  isCampoPaisDisabled = false;
  faltaInserirCEP = true;
  fotoCadastro = [];
  cameraAberta = false;
  possuiFoto = false;
  exlcuirFotoCadastro = false;
  tabelaDePrecoDesabilitada = false;
  usuarioSupervisor = false;
  modalTabelaDePreco = false;
  modalClasseSubClasse = false;
  tabelaDeClasseSubClasseDesabilitada = false;
  contaAgendamentoPagamentoDesabilitada = false;
  biometriaCapturada = '';

  //#endregion

  //#region Mutations

  @mutation
  obterEstadoPadrao(): void {
    this.pessoa = obterNovaPessoa();

    this.dadosIniciaisParaCadastro = obterDadosIniciaisCadatro();
    this.isNovoCadastroPessoa = true;
    this.isEnderecoCobranca = false;

    this.contatoAdicional = obterCampo(null);
    this.contatoAdicionalSelecionado = obterCampo(null);
    this.isNovoCadastroContatoAdicional = true;
    this.isContatoAdicionalAlterado = false;

    this.componenteEnderecoAdicional = {
      enderecoAdicional: obterCampo(null) as Campo<EnderecoAdicional | null>,
      ultimoCepEnviadoViaCep: '',
      isCampoCidadeDisabled: false,
      isCampoEstadoDisabled: false,
    };

    this.componenteDadosBancarios = {
      pessoaBancoSelecionado: obterCampo(null) as Campo<PessoaBanco | null>,
      bloquearAdicaoDadosBancarios: false,
    };

    this.anexosParaInserir = [];
    this.docsParaInserir = [];
    this.fotoAtual = undefined;
    this.fotoNovaParaInserir = undefined;
    this.fotoNovaParaExibir = undefined;

    this.isCampoCidadeDisabled = false;
    this.isCampoEstadoDisabled = false;
    this.isCampoPaisDisabled = false;

    this.faltaInserirCEP = true;
  }

  @mutation
  inseriuCep(bool: boolean): void {
    this.faltaInserirCEP = !bool;
  }

  @mutation
  sugerirClasseFiscalEndereco(exibeAlertaClasseFiscal = false): void {
    const estado = vxm.cadastro.pessoa.endereco.estado.descricao;
    const contribuiente = vxm.cadastro.pessoa.isContribuinteIcms.valor;

    if (estado === '') return;

    const classeFiscalSugerida = vxm.cadastro.dadosIniciaisParaCadastro.classesFiscaisRegra.find(
      ({ uf, contribuinte }) => contribuinte === contribuiente && uf === estado,
    );

    const pessoaPossuiClasseFiscalPreenchida = !!vxm.cadastro.pessoa.endereco.idClasseFiscal.valor;

    if (!pessoaPossuiClasseFiscalPreenchida && classeFiscalSugerida)
      vxm.cadastro.pessoa.endereco.idClasseFiscal.valor = classeFiscalSugerida.idClasseFiscal;
    else if (!pessoaPossuiClasseFiscalPreenchida && exibeAlertaClasseFiscal)
      AlertsExtension.exibirMensagemAlerta(
        "O estado selecionado não possui uma regra para classe fiscal.<br>Para configurar acesse: CRM>Cadastros Auxiliares>Classe Fiscal - Regras.<br>Caso não cadastre a regra, será gravada a mesma do consumidor final para esse cliente.<br>Dúvidas? Acesse nossa <a href='https://share.linx.com.br/display/SHOPLINXMICRPUB/Classe+Fiscal+-+Regras' target='_blank'>documentação</a>.",
        true,
      );
  }

  @mutation
  sugerirClasseFiscalEnderecoAdicional(): void {
    if (!vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor) return;

    if (!vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.utilizaEnderecoDocumentoFiscal.valor) return;

    const estado = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.estado.descricao;

    if (estado === '') return;

    const contribuiente = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.inscricaoEstadual.valor != '';
    const classeFiscalSugerida = vxm.cadastro.dadosIniciaisParaCadastro.classesFiscaisRegra.find(
      ({ uf, contribuinte }) => contribuinte === contribuiente && uf === estado,
    );

    const pessoaPossuiClasseFiscalPreenchida =
      !!vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.idClasseFiscal.valor;

    if (!pessoaPossuiClasseFiscalPreenchida && classeFiscalSugerida)
      vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.idClasseFiscal.valor = classeFiscalSugerida.idClasseFiscal;
  }

  @mutation
  desabilitaTabelaDePreco(bool: boolean): void {
    this.tabelaDePrecoDesabilitada = !bool;
  }

  @mutation
  desabilitaTabelaDeClasseSubClasse(bool: boolean): void {
    this.tabelaDeClasseSubClasseDesabilitada = !bool;
  }

  @mutation
  desabilitaContaAgendamentoPagamento(bool: boolean): void {
    this.contaAgendamentoPagamentoDesabilitada = !bool;
  }

  @mutation
  verificaUsuarioSupervisor(bool: boolean): void {
    this.usuarioSupervisor = bool;
  }

  @mutation
  desabilitaCampos(bool: boolean): void {
    this.isCampoCidadeDisabled = bool;
    this.isCampoEstadoDisabled = bool;
    this.isCampoPaisDisabled = bool;
    this.faltaInserirCEP = !bool;
  }

  @mutation
  desabilitaCidadeEstado(bool: boolean): void {
    this.isCampoCidadeDisabled = bool;
    this.isCampoEstadoDisabled = bool;
  }

  @mutation
  desabilitaPais(bool: boolean): void {
    this.isCampoPaisDisabled = bool;
  }

  @mutation
  desabilitaEstado(bool: boolean): void {
    this.isCampoEstadoDisabled = bool;
  }

  @mutation
  fecharModalCep(): void {
    this.modalEncontraCep = false;
    this.modalEncontraCepCobranca = false;
    this.modalEncontraCepAdicional = false;
  }

  @mutation
  abrirModalCep(): void {
    this.modalEncontraCep = true;
  }

  @mutation
  abrirModalTabelaDePreco(): void {
    this.modalTabelaDePreco = true;
  }

  @mutation
  fecharModalTabelaDePreco(): void {
    this.modalTabelaDePreco = false;
  }

  @mutation
  abrirModalClasseSubClasse(): void {
    this.modalClasseSubClasse = true;
  }

  @mutation
  fecharModalClasseSubClasse(): void {
    this.modalClasseSubClasse = false;
  }

  @mutation
  abrirModalCepCobranca(): void {
    this.modalEncontraCepCobranca = true;
  }

  @mutation
  abrirModalCepAdicional(): void {
    this.modalEncontraCepAdicional = true;
  }

  @mutation
  atualizaEnderecoApiInterna(RetornoObterCepPorEnderecoDTO: RetornoObterCepPorEnderecoDTO): void {
    this.pessoa.endereco.cep.valor = RetornoObterCepPorEnderecoDTO.CEP;
    this.pessoa.endereco.bairro.valor = RetornoObterCepPorEnderecoDTO.Bairro;
    this.pessoa.endereco.cidade.valor = RetornoObterCepPorEnderecoDTO.Cidade;
    this.pessoa.endereco.estado.valor = RetornoObterCepPorEnderecoDTO.UF;
    this.pessoa.endereco.logradouro.valor = RetornoObterCepPorEnderecoDTO.Logradouro;
    this.pessoa.endereco.numero.valor = RetornoObterCepPorEnderecoDTO.Numero;
  }

  @mutation
  atualizaEnderecoCobrancaApiInterna(RetornoObterCepPorEnderecoDTO: RetornoObterCepPorEnderecoDTO): void {
    this.pessoa.enderecoCobranca.cep.valor = RetornoObterCepPorEnderecoDTO.CEP;
    this.pessoa.enderecoCobranca.bairro.valor = RetornoObterCepPorEnderecoDTO.Bairro;
    this.pessoa.enderecoCobranca.cidade.valor = RetornoObterCepPorEnderecoDTO.Cidade;
    this.pessoa.enderecoCobranca.estado.valor = RetornoObterCepPorEnderecoDTO.UF;
    this.pessoa.enderecoCobranca.logradouro.valor = RetornoObterCepPorEnderecoDTO.Logradouro;
    this.pessoa.enderecoCobranca.numero.valor = RetornoObterCepPorEnderecoDTO.Numero;
  }

  @mutation
  atualizarInformacoesPessoa(): void {
    const foneDDD = vxm.parametros.parametrosCrm.foneDDD;
    this.pessoa.contatos.celular.valor = foneDDD;
    this.pessoa.contatos.telefonePrincipal.valor = foneDDD;
    this.pessoa.contatos.telefoneSecundario.valor = foneDDD;

    const utilizaIntegracaoOC = vxm.parametros.parametrosPortal.utilizaIntegracaoOC;
    if (utilizaIntegracaoOC) this.pessoa.tipoCliente.valor = TipoCliente.ambos;

    const utilizaSistemaTributacao = vxm.parametros.parametrosCrm.sistemaTributacao === 'S';
    if (!utilizaSistemaTributacao) this.pessoa.fornecedor.isLancaIcmsIpiEntradaDevolucaoCompras.valor = true;
  }

  //#region DadosBancarios

  @mutation
  obterNovoDadoBancario(): void {
    vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor = {
      isPix: obterCampo(false),
      idBancoCliente: obterCampo(Utils.obterIdAleatorio()),
      idBanco: obterCampo(null),
      idTipoContaBancaria: obterCampo(1),
      agencia: obterCampo(''),
      agenciaDigito: obterCampo(''),
      contaCorrente: obterCampo(''),
      contaCorrenteDigito: obterCampo(''),
      nomeContato: obterCampo(''),
      fone: obterCampo(vxm.parametros.parametrosCrm.foneDDD),
      valorLimiteConta: obterCampo(''),
      codigoCliente: obterCampo(''),
      isPrincipal: obterCampo(false),
      isContaAgendamentoPagamento: obterCampo(false),
      isAtivo: obterCampo(true),
      isNovoDadoBancario: obterCampo(true),
      nomeTitular: obterCampo(''),
      documentoTitular: obterCampo(''),
      preencherNomeDocumentoCliente: obterCampo(false),
      idTipoChavePix: obterCampo(null),
      chavePix: obterCampo(''),
    } as PessoaBanco;
  }

  @mutation
  limparDadoBancarioSelecionado(): void {
    vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor = null;
    vxm.cadastro.componenteDadosBancarios.bloquearAdicaoDadosBancarios = false;
  }

  @mutation
  atualizarTipoChavePix(idTipoChavePix: number | null): void {
    const dadosBancariosSelecionados = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado?.valor;
    if (dadosBancariosSelecionados) dadosBancariosSelecionados.idTipoChavePix.valor = idTipoChavePix;
  }

  @mutation
  definirNovoDadoBancarioParaContaAgendamentoPagamento(): void {
    vxm.cadastro.obterNovoDadoBancario();
    vxm.cadastro.definirIsContaAgendamentoPagamento();
    vxm.cadastro.componenteDadosBancarios.bloquearAdicaoDadosBancarios = true;
  }

  @mutation
  abrirDadosBancariosConsiderandoContaAgendamento(): void {
    const dadosBancarios = vxm.cadastro.pessoa.comercial.bancos.valor;
    if (!dadosBancarios?.length) {
      vxm.cadastro.definirNovoDadoBancarioParaContaAgendamentoPagamento();
      return;
    }

    if (dadosBancarios.length === 1) {
      vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor = dadosBancarios[0];
      return;
    }

    const dadoBancarioComAgendamento = dadosBancarios.find((db) => db.isContaAgendamentoPagamento.valor);
    if (dadoBancarioComAgendamento)
      vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor = dadoBancarioComAgendamento;
  }

  @mutation
  definirIsContaAgendamentoPagamento(): void {
    const dadosBancariosSelecionados = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado?.valor;
    if (!dadosBancariosSelecionados) return;

    dadosBancariosSelecionados.isContaAgendamentoPagamento.valor = true;
  }
  //#endregion

  //#region EnderecoAdicional

  @mutation
  atualizarEnderecoAdicionalAoBuscarEnderecoPorEndereco(RetornoObterCepPorEnderecoDTO: RetornoObterCepPorEnderecoDTO): void {
    if (this.componenteEnderecoAdicional.enderecoAdicional.valor) {
      this.componenteEnderecoAdicional.enderecoAdicional.valor.cep.valor = RetornoObterCepPorEnderecoDTO.CEP;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.bairro.valor = RetornoObterCepPorEnderecoDTO.Bairro;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.cidade.valor = RetornoObterCepPorEnderecoDTO.Cidade;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.estado.valor = RetornoObterCepPorEnderecoDTO.UF;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.logradouro.valor = RetornoObterCepPorEnderecoDTO.Logradouro;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.numero.valor = RetornoObterCepPorEnderecoDTO.Numero;
    }
  }

  @mutation
  atualizarEnderecoAdicionalAoBuscarEnderecoPorCep(endereco: Endereco | null): void {
    if (this.componenteEnderecoAdicional.enderecoAdicional.valor && endereco) {
      this.componenteEnderecoAdicional.enderecoAdicional.valor.cep.valor = endereco.cep.valor;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.bairro.valor = endereco.bairro.valor;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.cidade.valor = endereco.cidade.valor;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.estado.valor = endereco.estado.valor;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.logradouro.valor = endereco.logradouro.valor;
      this.componenteEnderecoAdicional.enderecoAdicional.valor.numero.valor = endereco.numero.valor;

      this.componenteEnderecoAdicional.ultimoCepEnviadoViaCep = endereco.cep.valor;

      if (endereco.estado.valor && endereco.cidade.valor) {
        this.componenteEnderecoAdicional.isCampoCidadeDisabled = true;
        this.componenteEnderecoAdicional.isCampoEstadoDisabled = true;
      } else {
        this.componenteEnderecoAdicional.isCampoCidadeDisabled = false;
        this.componenteEnderecoAdicional.isCampoEstadoDisabled = false;
      }
    } else {
      this.componenteEnderecoAdicional.isCampoCidadeDisabled = false;
      this.componenteEnderecoAdicional.isCampoEstadoDisabled = false;
    }
  }

  @mutation
  obterNovoEnderecoAdicional(): void {
    vxm.cadastro.componenteEnderecoAdicional.isCampoCidadeDisabled = false;
    vxm.cadastro.componenteEnderecoAdicional.isCampoEstadoDisabled = false;
    vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor = obterNovoEnderecoAdicional();
  }

  @mutation
  limparEnderecoAdicional(): void {
    vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor = null;
  }

  @mutation
  desmarcarCampoEnderecoPrincipalParaEnderecoAdicionalAntigo(): void {
    if (vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor) {
      vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor.forEach((end) => {
        end.isEnderecoPrincipal.valor = false;
      });
    }
  }

  @mutation
  atualizarEnderecoAdicionalExistente(): void {
    const enderecosAdicionais = vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor;
    const enderecoAdicional = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor;
    const indexEnderecoAdicionalExistente = vxm.cadastro.obterIndexEnderecoAdicionalExistente;

    if (enderecoAdicional && indexEnderecoAdicionalExistente !== -1) {
      enderecosAdicionais[indexEnderecoAdicionalExistente] = enderecoAdicional;
    }
  }

  @mutation
  salvarEnderecoAdicional(): void {
    const enderecosAdicionais = vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor;
    const enderecoAdicional = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor;
    const isNovoEnderecoAdicional = vxm.cadastro.isNovoEnderecoAdicional;

    if (enderecoAdicional) {
      const isEnderecoAdicionalValido = PessoaModel.validarEnderecoAdicional(enderecoAdicional);

      if (isEnderecoAdicionalValido) {
        const isEnderecoAdicionalPrincipal = enderecoAdicional.isEnderecoPrincipal.valor;
        const isAnyEnderecoAdicionalMarcadoComoPrincipal = vxm.cadastro.isAnyEnderecoAdicionalMarcadoComoPrincipal;

        if (isEnderecoAdicionalPrincipal && isAnyEnderecoAdicionalMarcadoComoPrincipal) {
          AlertsExtension.exibirMensagemPergunta(
            'Atenção',
            `Somente um endereço de entrega pode ser marcado como principal.
             Ao confirmar a gravação, o endereço de entrega principal anterior será desmarcado.
             <br>
             Deseja continuar?`,
            (result) => {
              if (result) {
                vxm.cadastro.desmarcarCampoEnderecoPrincipalParaEnderecoAdicionalAntigo();
                enderecoAdicional.isEnderecoPrincipal.valor = true;

                if (isNovoEnderecoAdicional) {
                  enderecoAdicional.novo.valor = true;
                  enderecosAdicionais.push(enderecoAdicional);
                } else vxm.cadastro.atualizarEnderecoAdicionalExistente();

                vxm.cadastro.limparEnderecoAdicional();

                AlertsExtension.exibirMensagemSucesso('Endereço adicional salvo com sucesso', false);
              } else enderecoAdicional.isEnderecoPrincipal.valor = false;
            },
          );
        } else {
          if (isNovoEnderecoAdicional) {
            enderecoAdicional.novo.valor = true;
            enderecosAdicionais.push(enderecoAdicional);
          } else vxm.cadastro.atualizarEnderecoAdicionalExistente();

          vxm.cadastro.limparEnderecoAdicional();

          AlertsExtension.exibirMensagemSucesso('Endereço adicional salvo com sucesso', false);
        }
      }
    }
  }

  @mutation
  excluirEnderecoAdicionalExistente(): void {
    if (!vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor?.permiteExcluir.valor) {
      AlertsExtension.exibirMensagemErro('Não é possível excluir um endereço já utilizado!', false);
    } else {
      AlertsExtension.exibirMensagemPergunta('Endereço Adicional', 'Tem certeza que deseja excluir este registro?', (result) => {
        const enderecoAdicional = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor;
        const enderecosAdicionais = vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor;

        if (result && enderecoAdicional) {
          if (enderecoAdicional.novo.valor) {
            const indexEnderecoAdicionalExistente = vxm.cadastro.obterIndexEnderecoAdicionalExistente;
            enderecosAdicionais.splice(indexEnderecoAdicionalExistente, 1);

            vxm.cadastro.limparEnderecoAdicional();
          } else {
            enderecoAdicional.excluido.valor = true;
            vxm.cadastro.atualizarEnderecoAdicionalExistente();
            vxm.cadastro.limparEnderecoAdicional();
          }

          AlertsExtension.exibirMensagemSucesso('Endereço adicional excluído com sucesso', false);
        }
      });
    }
  }

  @mutation
  atualizarEnderecoAdicionalDescricaoEstado(descricaoEstado: string): void {
    if (vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor)
      vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.estado.descricao = descricaoEstado;
  }
  //#endregion

  //#endregion

  //#region Actions

  @action
  async obterPessoaPorDocumento(documento: string): Promise<Pessoa> {
    return CrmClient.obterPessoaPorDocumento(documento).then((obterPessoaPorDocumentoResult) => {
      const pessoa = obterPessoaMapping(obterPessoaPorDocumentoResult, this.dadosIniciaisParaCadastro);
      this.pessoaObtidaSemAlteracoes = JSON.parse(JSON.stringify(pessoa));
      return pessoa;
    });
  }

  @action
  async obterPessoaPorId(idPessoa: number): Promise<Pessoa> {
    return CrmClient.obterPessoaPorId(idPessoa).then((obterPessoaPorIdResult) => {
      const pessoa = obterPessoaMapping(obterPessoaPorIdResult, this.dadosIniciaisParaCadastro);
      this.pessoaObtidaSemAlteracoes = JSON.parse(JSON.stringify(pessoa));
      return pessoa;
    });
  }

  @action
  async obterDadosEnderecoViaCep(cep: string): Promise<Endereco> {
    return ViaCepCliente.buscarDadosEndereco(cep.trim()).then((buscarDadosEnderecoResult) => {
      if (buscarDadosEnderecoResult.erro)
        throw new ApiError({ status: 404, title: 'Não foi possível encontrar este cep.' } as ApiErrorContract);
      return obterEnderecoMapping(buscarDadosEnderecoResult, this.dadosIniciaisParaCadastro.estados);
    });
  }
  @action
  async obterDadosReceita(cnpj: string): Promise<DadosCnpj> {
    return DadosReceitaWs.buscarDadosCnpj(cnpj).then((buscarDadosEnderecoResult) => {
      return obterDadosCnpjReceitaMapping(buscarDadosEnderecoResult, this.dadosIniciaisParaCadastro.estados);
    });
    ('');
  }

  @action
  async obterCepPorEndereco(payload: ObterCepPorEnderecoDTO): Promise<Array<RetornoObterCepPorEnderecoDTO>> {
    return CrmClient.obterCepPorEndereco(payload);
  }

  @action
  async obterDadosIniciaisCadastro(): Promise<DadosIniciaisCadastroComParametros> {
    return CrmClient.obterDadosIniciaisCadastroCliente().then((dadosIniciaisCadastroResult) => {
      this.verificaUsuarioSupervisor(dadosIniciaisCadastroResult.UsuarioSupervisor);
      this.desabilitaTabelaDePreco(dadosIniciaisCadastroResult.DireitoTabelaDePrecos);
      this.desabilitaTabelaDeClasseSubClasse(dadosIniciaisCadastroResult.DireitoTabelaDeClasseSubClasse);
      this.desabilitaContaAgendamentoPagamento(dadosIniciaisCadastroResult.PortalTemContaDigitalIntegrada);

      const dadosIniciaisCadastroComParametros = obterDadosIniciaisCadastroMapping(dadosIniciaisCadastroResult);

      vxm.cadastro.dadosIniciaisParaCadastro = dadosIniciaisCadastroComParametros.dadosIniciaisCadastro;

      vxm.parametros.parametrosCrm = dadosIniciaisCadastroComParametros.parametros.parametrosCrm;
      vxm.parametros.permissoesUsuario = dadosIniciaisCadastroComParametros.parametros.permissoesUsuario;
      vxm.parametros.parametrosPortal = dadosIniciaisCadastroComParametros.parametros.parametrosPortal;
      vxm.parametros.parametrosPos = dadosIniciaisCadastroComParametros.parametros.parametrosPos;
      vxm.parametros.utilizaIntegracaoSalesForce = dadosIniciaisCadastroComParametros.utilizaIntegracaoSalesForce;
      vxm.parametros.analiseDeCreditoAtiva = dadosIniciaisCadastroComParametros.analiseDeCreditoAtiva;

      this.atualizarInformacoesPessoa();

      return dadosIniciaisCadastroComParametros;
    });
  }

  @action
  async cadastrarPessoa(pessoa: Pessoa): Promise<number> {
    const inserirPessoaDTO = obterSalvarPessoaDTO(pessoa, this.dadosIniciaisParaCadastro);
    return CrmClient.inserirPessoa(inserirPessoaDTO);
  }

  @action
  async listarUsuariosSupervisores(): Promise<ObterUsuariosSupervisoresResult> {
    return CrmClient.obterUsuariosSupervisores();
  }

  @action
  async atualizarPessoa(pessoa: Pessoa): Promise<void> {
    const atualizarPessoaDTO = obterSalvarPessoaDTO(pessoa, this.dadosIniciaisParaCadastro);
    return CrmClient.atualizarPessoa(atualizarPessoaDTO);
  }

  @action
  async atualizarPessoaBancos(payload: AtualizarPessoaBancoPayload): Promise<void> {
    const atualizarPessoaBancoDTO = obterSalvarPessoaBancosDTO(payload.Pessoa);

    let atualizado: void;
    let message;
    try {
      atualizado = await CrmClient.atualizarPessoaBancos(atualizarPessoaBancoDTO);
      message = { ...atualizarPessoaBancoDTO, ExcluirPessoaBanco: payload.Excluir };
    } catch (error) {
      message = 'Mensagem não enviada';
      console.warn(message, atualizarPessoaBancoDTO);
      throw new Error('Não foi possível atualizar os dados bancários');
    }

    window.parent.window.postMessage(message, '*');

    return atualizado;
  }

  @action gerarAnaliseCredito(idCliente: number): Promise<void> {
    return CrmClient.gerarAnaliseCredito(idCliente);
  }

  @action
  async downloadAnexo(idAnexo: number): Promise<Blob> {
    return CrmClient.downloadAnexo(idAnexo);
  }

  @action
  async excluirAnexo(idAnexo: number): Promise<void> {
    return CrmClient.excluirAnexo(idAnexo).then(() => {
      const anexoIndex = this.pessoa.dadosAdicionais.anexos.valor.findIndex((anexo) => anexo.id === idAnexo);
      if (anexoIndex > -1) {
        this.pessoa.dadosAdicionais.anexos.valor.splice(anexoIndex, 1);
      }
      return;
    });
  }

  @action
  async inserirAnexos(idPessoa: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.anexosParaInserir.forEach(async (anexo) => {
        const formData = new FormData();
        formData.append('file', anexo);
        formData.append('idPessoa', idPessoa.toString());
        await CrmClient.inserirAnexo(formData).catch((error) => console.log(error));
      });
      resolve();
    });
  }

  @action
  async downloadDoc(idDoc: number): Promise<Blob> {
    return CrmClient.downloadDoc(idDoc);
  }

  @action
  async excluirDoc(idDoc: number): Promise<void> {
    return CrmClient.excluirDoc(idDoc).then(() => {
      const docIndex = this.pessoa.docs.valor.findIndex((doc) => doc.id === idDoc);
      if (docIndex > -1) {
        this.pessoa.docs.valor.splice(docIndex, 1);
      }
      return;
    });
  }

  @action
  async inserirDocs(idPessoa: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.docsParaInserir.forEach(async (doc) => {
        const formData = new FormData();
        formData.append('file', doc.file);
        formData.append('descricao', doc.descricao);
        if (doc.tipoDoc) formData.append('tipoDoc', doc.tipoDoc.toString());
        formData.append('idPessoa', idPessoa.toString());
        await CrmClient.inserirDoc(formData).catch((error: ApiError) => {
          reject(
            'Documento não pôde ser inserido corretamente, pois seu nome excede o tamanho correto de 46 caracteres: ' +
              error.detail,
          );
        });
      });
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  @action
  async inserirFotoPessoa(idPessoa: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.fotoNovaParaInserir) {
        const formData = new FormData();
        formData.append('file', this.fotoNovaParaInserir);
        formData.append('idPessoa', idPessoa.toString());
        CrmClient.inserirFotoPessoa(formData)
          .then(() => resolve)
          .catch((error: ApiError) => console.log(error));
      }
      resolve();
    });
  }

  @action
  async obterFotoPessoa(idPessoa: number): Promise<Blob> {
    return CrmClient.obterFotoPessoa(idPessoa);
  }
  @action
  async excluirFoto(id: number): Promise<void> {
    this.possuiFoto = false;
    return CrmClient.excluirFoto(id);
  }

  //#region EnderecoAdicional

  @action
  async obterEnderecoAdicionalPorCep(): Promise<void> {
    const enderecoAdicional = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional;

    if (enderecoAdicional.valor && enderecoAdicional.valor.cep.valor) {
      const isCepValido = PessoaModel.validarCep(enderecoAdicional.valor.cep);

      if (isCepValido) {
        vxm.loading.showLoading('Buscando dados do endereço adicional ...');

        vxm.cadastro
          .obterDadosEnderecoViaCep(enderecoAdicional.valor.cep.valor)
          .then((enderecoCliente) => {
            vxm.cadastro.atualizarEnderecoAdicionalAoBuscarEnderecoPorCep(enderecoCliente);
          })
          .catch((error) => {
            vxm.cadastro.atualizarEnderecoAdicionalAoBuscarEnderecoPorCep(null);
            if (error instanceof ApiError && error.status === 404) AlertsExtension.exibirMensagemAlerta(error.title, true);
            else AlertsExtension.exibirMensagemErro(error, false);
          })
          .finally(() => {
            vxm.loading.hideLoading();
          });
      }
    }
  }

  @action
  async permiteExcluirContato(idContato: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      vxm.loading.showLoading();
      CrmClient.permiteExcluirContato(idContato)
        .then((permiteExcluirContato) => {
          resolve(permiteExcluirContato);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          vxm.loading.hideLoading();
        });
    });
  }

  @action
  async atualizarBiometria(biometriaClienteCrm: BiometriaClienteCrm): Promise<void> {
    return new Promise((resolve, reject) => {
      CrmClient.atualizarBiometria(biometriaClienteCrm)
        .then(() => resolve)
        .catch((error: ApiError) => console.log(error));

      resolve();
    });
  }

  //#endregion

  //#region DadosBancarios

  @action
  async excluirDadoBancarioSelecionado(secaoCadastroParcial: boolean): Promise<boolean> {
    let sucesso = false;
    await AlertsExtension.exibirMensagemPergunta('Dados Bancários', 'Tem certeza que deseja excluir este registro?', (result) => {
      const dadosBancarios = vxm.cadastro.pessoa.comercial.bancos.valor;
      if (!result) {
        sucesso = false;
        return;
      }

      dadosBancarios.splice(vxm.cadastro.obterIndexDadoBancarioSelecionado, 1);
      vxm.cadastro.limparDadoBancarioSelecionado();

      if (!secaoCadastroParcial) AlertsExtension.exibirMensagemSucesso('Dado bancário excluído com sucesso', false);

      sucesso = true;
    });

    return sucesso;
  }

  @action
  async salvarDadoBancarioSelecionado(secaoCadastroParcial: boolean): Promise<boolean> {
    const dadosBancarios = vxm.cadastro.pessoa.comercial.bancos.valor;
    const dadoBancarioSelecionado = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor;
    const isNovoDadoBancario = vxm.cadastro.isNovoDadoBancario;

    if (!dadoBancarioSelecionado) return false;

    const isDadosBancariosValido = PessoaModel.validarPessoaBanco(dadoBancarioSelecionado);

    if (!isDadosBancariosValido) return false;

    if (dadosBancarios.length != 0) {
      const controleDadoBancarioAnterior = {} as ControleClicouEmSimParaDesmarcar;

      const gerarControleClicouEmSimParaDesmarcar = (prop: PropClicouEmSimParaDesmarcar) => {
        const dadoAnteriorSelecionado = dadosBancarios.find(
          (dadoBancario) =>
            dadoBancario[prop].valor && dadoBancarioSelecionado.idBancoCliente.valor != dadoBancario.idBancoCliente.valor,
        );

        if (dadoAnteriorSelecionado) controleDadoBancarioAnterior[prop] = dadoAnteriorSelecionado;
      };

      gerarControleClicouEmSimParaDesmarcar('isPrincipal');
      gerarControleClicouEmSimParaDesmarcar('isContaAgendamentoPagamento');

      const temContaPrincipal =
        controleDadoBancarioAnterior.isPrincipal?.isPrincipal?.valor && dadoBancarioSelecionado.isPrincipal.valor;
      const temContaAgendamento =
        controleDadoBancarioAnterior.isContaAgendamentoPagamento?.isContaAgendamentoPagamento?.valor &&
        dadoBancarioSelecionado.isContaAgendamentoPagamento.valor;

      const exibirPergunta = async (
        validacao: boolean,
        pergunta: string,
        prop: PropClicouEmSimParaDesmarcar,
      ): Promise<boolean> => {
        let sucesso = true;

        if (validacao) {
          await AlertsExtension.exibirMensagemPergunta('Atenção', pergunta, (result) => {
            if (!result) sucesso = false;

            controleDadoBancarioAnterior[prop][prop].valor = false;
          });
        }

        return sucesso;
      };

      const aceitouPerguntaContaPrincipal = await exibirPergunta(
        temContaPrincipal,
        `Somente um dado bancário pode ser marcado como principal.
      Ao confirmar a gravação, o dado bancário anterior será desmarcado.
      <br>
      Deseja continuar?`,
        'isPrincipal',
      );

      if (!aceitouPerguntaContaPrincipal) return false;

      const aceitouPerguntaContaAgendamento = await exibirPergunta(
        temContaAgendamento,
        `Somente um dado bancário/PIX pode ser utilizado para agendamento de pagamentos. Ao confirmar a gravação, o dado bancário/PIX anterior será desmarcado.
               <br>
               Deseja continuar?`,
        'isContaAgendamentoPagamento',
      );

      if (!aceitouPerguntaContaAgendamento) return false;
    }

    if (isNovoDadoBancario) dadosBancarios.push(dadoBancarioSelecionado);
    else dadosBancarios[vxm.cadastro.obterIndexDadoBancarioSelecionado] = dadoBancarioSelecionado;

    vxm.cadastro.limparDadoBancarioSelecionado();
    if (!secaoCadastroParcial)
      AlertsExtension.exibirMensagemSucesso(
        `Dado Bancário/PIX salvo com sucesso${
          !vxm.cadastro.isNovoCadastroPessoa ? '. Por favor, clique em "Atualizar" para aplicar' : ''
        }`,
        false,
      );

    return true;
  }

  //#endregion

  //#endregion

  //#region Getters

  get contatosAdicionaisMultiselect(): Array<ContatoAdicionalMultiselect> {
    return vxm.cadastro.pessoa.contatos.contatosAdicionais.valor.map(({ id, nome }) =>
      obterCampoMultiselect(Number(id.valor), nome.valor),
    );
  }

  get isNovoEnderecoAdicional(): boolean {
    const indexEnderecoAdicionalExistente = vxm.cadastro.obterIndexEnderecoAdicionalExistente;
    return indexEnderecoAdicionalExistente === -1;
  }

  get obterIndexEnderecoAdicionalExistente(): number {
    const enderecosAdicionais = vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor;
    const enderecoAdicional = vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor;

    if (enderecoAdicional && enderecosAdicionais.length !== 0) {
      const indexEnderecoAdicionalExistente = enderecosAdicionais.findIndex(({ id }) => id.valor === enderecoAdicional.id.valor);
      return indexEnderecoAdicionalExistente;
    }

    return -1;
  }

  get obterIndexDadoBancarioSelecionado(): number {
    const dadoBancarioSelecionado = vxm.cadastro.componenteDadosBancarios.pessoaBancoSelecionado.valor;
    const dadosBancarios = vxm.cadastro.pessoa.comercial.bancos.valor;

    if (dadosBancarios && dadosBancarios.length !== 0) {
      const indexEnderecoAdicionalExistente = dadosBancarios.findIndex(
        ({ idBancoCliente }) => idBancoCliente.valor === dadoBancarioSelecionado?.idBancoCliente.valor,
      );
      return indexEnderecoAdicionalExistente;
    }

    return -1;
  }

  get isNovoDadoBancario(): boolean {
    return vxm.cadastro.obterIndexDadoBancarioSelecionado == -1;
  }

  get isAnyEnderecoAdicionalMarcadoComoPrincipal(): boolean {
    return vxm.cadastro.pessoa.endereco.enderecosAdicionais.valor.some((end) => {
      return (
        end.isEnderecoPrincipal.valor &&
        end.id.valor != vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor?.id.valor
      );
    });
  }

  get obterMultiselectEstadosComEstadoEX(): Array<Estado> {
    return [CamposTypes.obterCampoMultiselect(9999, 'EX', true), ...vxm.cadastro.dadosIniciaisParaCadastro.estados];
  }

  get obterMultiselectClassesFiscais(): Array<ClassesFiscais> {
    return [...vxm.cadastro.dadosIniciaisParaCadastro.classesFiscais];
  }

  get obterIdEstadoSelecionadoEnderecoAdicional(): number {
    if (vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor)
      return Number(vxm.cadastro.componenteEnderecoAdicional.enderecoAdicional.valor.estado.valor);
    else return 0;
  }

  get obterCelularSemDDD(): string {
    if (vxm.cadastro.pessoa.contatos.celular.valor.length > 1) {
      return vxm.cadastro.pessoa.contatos.celular.valor.slice(1);
    }
    return '';
  }

  get isPessoaCliente(): boolean {
    return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.cliente;
  }

  get isPessoaFornecedor(): boolean {
    return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.fornecedor;
  }

  get isPessoaTransportador(): boolean {
    return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.transportador;
  }

  get isPessoaAmbos(): boolean {
    return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambos;
  }

  get isPessoaAmbosTransportador(): boolean {
    return vxm.cadastro.pessoa.tipoCliente.valor === TipoCliente.ambosTransportador;
  }
  //#endregion
}
